/* jslint white: true, nomen: true */
/* global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')
    .service('dummyDataService', [function() {

      var states = [{
        "name" : "Alabama",
        "abbreviation" : "AL"
      }, {
        "name" : "Alaska",
        "abbreviation" : "AK"
      }, {
        "name" : "Arizona",
        "abbreviation" : "AZ"
      }, {
        "name" : "Arkansas",
        "abbreviation" : "AR"
      }, {
        "name" : "California",
        "abbreviation" : "CA"
      }, {
        "name" : "Colorado",
        "abbreviation" : "CO"
      }, {
        "name" : "Connecticut",
        "abbreviation" : "CT"
      }, {
        "name" : "Delaware",
        "abbreviation" : "DE"
      }, {
        "name" : "District Of Columbia",
        "abbreviation" : "DC"
      }, {
        "name" : "Florida",
        "abbreviation" : "FL"
      }, {
        "name" : "Georgia",
        "abbreviation" : "GA"
      }, {
        "name" : "Hawaii",
        "abbreviation" : "HI"
      }, {
        "name" : "Idaho",
        "abbreviation" : "ID"
      }, {
        "name" : "Illinois",
        "abbreviation" : "IL"
      }, {
        "name" : "Indiana",
        "abbreviation" : "IN"
      }, {
        "name" : "Iowa",
        "abbreviation" : "IA"
      }, {
        "name" : "Kansas",
        "abbreviation" : "KS"
      }, {
        "name" : "Kentucky",
        "abbreviation" : "KY"
      }, {
        "name" : "Louisiana",
        "abbreviation" : "LA"
      }, {
        "name" : "Maine",
        "abbreviation" : "ME"
      }, {
        "name" : "Maryland",
        "abbreviation" : "MD"
      }, {
        "name" : "Massachusetts",
        "abbreviation" : "MA"
      }, {
        "name" : "Michigan",
        "abbreviation" : "MI"
      }, {
        "name" : "Minnesota",
        "abbreviation" : "MN"
      }, {
        "name" : "Mississippi",
        "abbreviation" : "MS"
      }, {
        "name" : "Missouri",
        "abbreviation" : "MO"
      }, {
        "name" : "Montana",
        "abbreviation" : "MT"
      }, {
        "name" : "Nebraska",
        "abbreviation" : "NE"
      }, {
        "name" : "Nevada",
        "abbreviation" : "NV"
      }, {
        "name" : "New Hampshire",
        "abbreviation" : "NH"
      }, {
        "name" : "New Jersey",
        "abbreviation" : "NJ"
      }, {
        "name" : "New Mexico",
        "abbreviation" : "NM"
      }, {
        "name" : "New York",
        "abbreviation" : "NY"
      }, {
        "name" : "North Carolina",
        "abbreviation" : "NC"
      }, {
        "name" : "North Dakota",
        "abbreviation" : "ND"
      }, {
        "name" : "Ohio",
        "abbreviation" : "OH"
      }, {
        "name" : "Oklahoma",
        "abbreviation" : "OK"
      }, {
        "name" : "Oregon",
        "abbreviation" : "OR"
      }, {
        "name" : "Pennsylvania",
        "abbreviation" : "PA"
      }, {
        "name" : "Rhode Island",
        "abbreviation" : "RI"
      }, {
        "name" : "South Carolina",
        "abbreviation" : "SC"
      }, {
        "name" : "South Dakota",
        "abbreviation" : "SD"
      }, {
        "name" : "Tennessee",
        "abbreviation" : "TN"
      }, {
        "name" : "Texas",
        "abbreviation" : "TX"
      }, {
        "name" : "Utah",
        "abbreviation" : "UT"
      }, {
        "name" : "Vermont",
        "abbreviation" : "VT"
      }, {
        "name" : "Virginia",
        "abbreviation" : "VA"
      }, {
        "name" : "Washington",
        "abbreviation" : "WA"
      }, {
        "name" : "West Virginia",
        "abbreviation" : "WV"
      }, {
        "name" : "Wisconsin",
        "abbreviation" : "WI"
      }, {
        "name" : "Wyoming",
        "abbreviation" : "WY"
      }];

      var newStates = [
        {key: 'AL', value: 'AL'},
        {key: 'AK', value: 'AK'},
        {key: 'AZ', value: 'AZ'},
        {key: 'AR', value: 'AR'},
        {key: 'CA', value: 'CA'},
        {key: 'CO', value: 'CO'},
        {key: 'CT', value: 'CT'},
        {key: 'DE', value: 'DE'},
        {key: 'DC', value: 'DC'},
        {key: 'FL', value: 'FL'},
        {key: 'GA', value: 'GA'},
        {key: 'HI', value: 'HI'},
        {key: 'ID', value: 'ID'},
        {key: 'IL', value: 'IL'},
        {key: 'IN', value: 'IN'},
        {key: 'IA', value: 'IA'},
        {key: 'KS', value: 'KS'},
        {key: 'KY', value: 'KY'},
        {key: 'LA', value: 'LA'},
        {key: 'ME', value: 'ME'},
        {key: 'MD', value: 'MD'},
        {key: 'MA', value: 'MA'},
        {key: 'MI', value: 'MI'},
        {key: 'MN', value: 'MN'},
        {key: 'MS', value: 'MS'},
        {key: 'MO', value: 'MO'},
        {key: 'MT', value: 'MT'},
        {key: 'NE', value: 'NE'},
        {key: 'NV', value: 'NV'},
        {key: 'NH', value: 'NH'},
        {key: 'NJ', value: 'NJ'},
        {key: 'NM', value: 'NM'},
        {key: 'NY', value: 'NY'},
        {key: 'NC', value: 'NC'},
        {key: 'ND', value: 'ND'},
        {key: 'OH', value: 'OH'},
        {key: 'OK', value: 'OK'},
        {key: 'OR', value: 'OR'},
        {key: 'PA', value: 'PA'},
        {key: 'RI', value: 'RI'},
        {key: 'SC', value: 'SC'},
        {key: 'SD', value: 'SD'},
        {key: 'TN', value: 'TN'},
        {key: 'TX', value: 'TX'},
        {key: 'UT', value: 'UT'},
        {key: 'VT', value: 'VT'},
        {key: 'VI', value: 'VI'},
        {key: 'VA', value: 'VA'},
        {key: 'WA', value: 'WA'},
        {key: 'WV', value: 'WV'},
        {key: 'WI', value: 'WI'},
        {key: 'WY', value: 'WY'}
      ];
    
      var hears = [{
        "value" : "SHH Website",
        "name" : "SHH Website"
      }, {
        "value" : "Friend/Referral",
        "name" : "Friend/Referral"
      }, {
        "value" : "Signs/Billboard",
        "name" : "Signs/Billboard"
      }, {
        "value" : "Drive By",
        "name" : "Drive By"
      }, {
        "value" : "Social Media",
        "name" : "Social Media"
      }, {
        "value" : "Real Estate Agent",
        "name" : "Real Estate Agent"
      }, {
        "value" : "Newspaper Ad",
        "name" : "Newspaper Ad"
      }, {
        "value" : "Postcard in the Mail",
        "name" : "Postcard in the Mail"
      }, {
        "value" : "Zillow – Internet",
        "name" : "Zillow – Internet"
      }, {
        "value" : "Trulia – Internet",
        "name" : "Trulia – Internet"
      }, {
        "value" : "Redfin - Internet",
        "name" : "Redfin - Internet"
      }, {
        "value" : "SummerHill Event",
        "name" : "SummerHill Event"
      }, {
        "value" : "Other",
        "name" : "Other"
      }];

      var newHears = [
        {key: 'SHH Website', value: 'SHH Website'},
        {key: 'Friend/Referral', value: 'Friend/Referral'},
        {key: 'Signs/Billboard', value: 'Signs/Billboard'},
        {key: 'Drive By', value: 'Drive By'},
        {key: 'Social Media', value: 'Social Media'},
        {key: 'Real Estate Agent', value: 'Real Estate Agent'},
        {key: 'Newspaper Ad', value: 'Newspaper Ad'},
        {key: 'Postcard in the Mail', value: 'Postcard in the Mail'},
        {key: 'Zillow – Internet', value: 'Zillow – Internet'},
        {key: 'Trulia – Internet', value: 'Trulia – Internet'},
        {key: 'Redfin - Internet', value: 'Redfin - Internet'},
        {key: 'SummerHill Event', value: 'SummerHill Event'},
        {key: 'Other', value: 'Other'}
      ];

      var contacts = [
        {key: 'email', value: 'Information via Email'},
        {key: 'phone', value: 'Information via Phone Call'},
        {key: 'schedule-a-tour', value: 'Schedule a Tour'}
      ];

      var tourTimes = [
        {key: '10:00AM', value: '10:00 AM'},
        {key: '11:00AM', value: '11:00 AM'},
        {key: '12:00PM', value: '12:00 PM'},
        {key: '1:00PM', value: '1:00 PM'},
        {key: '2:00PM', value: '2:00 PM'},
        {key: '3:00PM', value: '3:00 PM'},
        {key: '4:00PM', value: '4:00 PM'}
      ];

      var reasons = [
        {key: 'Downsizing', value: 'Downsizing'},
        {key: 'Need more space', value: 'Need more space'},
        {key: 'Schools', value: 'Schools'},
        {key: 'Closer commute', value: 'Closer commute'},
        {key: '1st time homebuyer', value: '1st time homebuyer'},
        {key: 'Other', value: 'Other'}
      ];

      this.states = function() {
        return states;
      }

      this.newStates = function() {
        return newStates;
      }

      this.hears = function() {
        return hears;
      }

      this.newHears = function() {
        return newHears;
      }

      this.contacts = function() {
        return contacts;
      }

      this.tourTimes = function() {
        return tourTimes;
      }

      this.reasons = function() {
        return reasons;
      }

  }]);
}());
