"use strict";

angular.module('app.comingSoon')
.controller('ComingSoonCtrl',['$scope', "$location", function ($scope, $location) {

	$scope.closed = true;

	$(function() {
		$("#micrositeCarousel").addClass("fade-in");
		$('.carousel-control').addClass('slide-in');
		$(".carousel-indicators").addClass("fade-in");
		$(".box").addClass("flip-in");
    $("img.down-arrow").addClass("fade-in-carousel-arrow"); 

	 });

   $scope.toggle = true;
    
    $scope.$watch('toggle', function(){
        $scope.toggleText = $scope.toggle ? 'Show Filters +' : 'Hide Filters -';
    })

var controller = new ScrollMagic.Controller();

  // create animation
  var tween = TweenMax.to('.carousel-arrow-down', 0.5, {
    marginTop: 0,
    //opacity: 0,
  });


    // Create the Scene and trigger when visible with ScrollMagic
  var scene = new ScrollMagic.Scene({
    triggerElement: '#carousel-arrow-down', 
    //triggerHook: 'onEnter',
     duration: 200,
    offset: 150, /* offset the trigger 150px below #scene's top */
  })
  .setTween(tween)
  .addTo(controller);

$scope.activeTab = function(tab) {
  return '/' + tab === $location.path();
};

	$scope.communities = [
    {
      name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'null',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    },
    {
      name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'null',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    },
    {
      name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'null',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    },
    {
      name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'sold-out',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    },
    {
      name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'null',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    },
    {
     name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'null',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    },
    {
     name: 'RESIDENCE 2A',
      price: 995644,
      beds: 3,
      baths: 2.5,
      sqft: 1568,
      status: 'null',
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true
    }
  ];

  $scope.features = [
		{
			title: "kitchen",
			text: ['Green Point Rated', 'Tankless Hot Water Heater', '15% Plus Over T-24 Requirements', 'High Efficiency Irrigation Systems', 'Energy Star Appliances', 'Low Voc Paints', 'Caulking and Construction Adhesives', 'Energy Star Bath Fans', 'Hvac Filter Merv 8', 'High Efficiency Air Conditioner With Environmentally Responsible Refrigerants'],
			greenFeature: false,
			group: 'included' 
		},
		{
			title: "bathrooms",
			text: ['Green Point Rated', 'Tankless Hot Water Heater', '15% Plus Over T-24 Requirements', 'High Efficiency Irrigation Systems', 'Energy Star Appliances', 'Low Voc Paints', 'Caulking and Construction Adhesives', 'Energy Star Bath Fans', 'Hvac Filter Merv 8', 'High Efficiency Air Conditioner With Environmentally Responsible Refrigerants'],
			greenFeature: false,
			group: 'included'
		},
		{
			title: "doors & windows",
			text: ['Green Point Rated', 'Tankless Hot Water Heater', '15% Plus Over T-24 Requirements', 'High Efficiency Irrigation Systems', 'Energy Star Appliances', 'Low Voc Paints', 'Caulking and Construction Adhesives', 'Energy Star Bath Fans', 'Hvac Filter Merv 8', 'High Efficiency Air Conditioner With Environmentally Responsible Refrigerants'],
			greenFeature: false,
			group: 'included'
		},
		{
			title: "Included green features",
			text: ['Green Point Rated', 'Tankless Hot Water Heater', '15% Plus Over T-24 Requirements', 'High Efficiency Irrigation Systems', 'Energy Star Appliances', 'Low Voc Paints', 'Caulking and Construction Adhesives', 'Energy Star Bath Fans', 'Hvac Filter Merv 8', 'High Efficiency Air Conditioner With Environmentally Responsible Refrigerants'],
			greenFeature: true,
			group: 'optional'
		},
		{
			title: "Optional green features",
			text: ['Green Point Rated', 'Tankless Hot Water Heater', '15% Plus Over T-24 Requirements', 'High Efficiency Irrigation Systems', 'Energy Star Appliances', 'Low Voc Paints', 'Caulking and Construction Adhesives', 'Energy Star Bath Fans', 'Hvac Filter Merv 8', 'High Efficiency Air Conditioner With Environmentally Responsible Refrigerants'],
			greenFeature: true,
			group: 'optional'
		},
		{
			title: "optimal upgrades",
			text: ['Green Point Rated', 'Tankless Hot Water Heater', '15% Plus Over T-24 Requirements', 'High Efficiency Irrigation Systems', 'Energy Star Appliances', 'Low Voc Paints', 'Caulking and Construction Adhesives', 'Energy Star Bath Fans', 'Hvac Filter Merv 8', 'High Efficiency Air Conditioner With Environmentally Responsible Refrigerants'],
			greenFeature: false,
			group: 'optional' 
		}
	];

  $scope.amenities = [
    {
      name: 'Stratford School',
      address: '820 West McKinley Avenue Sunnyvale, CA 94086',
    },
    {
      name: 'Oak Avenue Elementary School',
      address: '1501 Oak Avenue Los Altos, CA 94024',
    },
    {
      name: 'William Faria Elementary School',
      address: '10155 Barbara Lane Cupertino, CA 95014-2901',
    },
  ];

}]);
