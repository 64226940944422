(function() {
  "use strict";

  angular

    .module('app.homepage.controllers')

    .controller('companyInfoCtrl', ['$scope',
      function($scope) {

        function animateValue(obj, start, end, duration) {
          let startTimestamp = null;
          const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
              window.requestAnimationFrame(step);
            }
          };
          window.requestAnimationFrame(step);
        }

        // const obj = document.getElementById("value");
        const objFounded = angular.element('.boxes-numbers h2.founded')[0];
        const objHomes = angular.element('.boxes-numbers h2.homes')[0];
        const objCommunities = angular.element('.boxes-numbers h2.communities')[0];

        animateValue(objFounded, 0, 1976, 3000);
        animateValue(objHomes, 0, 6836, 3000);
        animateValue(objCommunities, 0, 81, 3000);

      }
    ])

  ;
})();
