(function() {
  'use strict';

  angular.module('app')
    .controller('carouselModalCtrl', ['$scope', '$modalInstance',
      function($scope, $modalInstance) {

        $scope.selectedIndex = 0;

        $scope.close = function() {
          $modalInstance.close();
        };

      }]);
})();