(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("TestimonialsDataService", ['$http', '$q', 'TestimonialsCachedDataService',
      function($http, $q, TestimonialsCachedDataService) {

        var data = {
          testimonials: [],
          promise: null
        };


        if (TestimonialsCachedDataService.testimonials.length > 0) {

          // Wrap the cached data in a promise to provide a consistent api
          data.promise = $q.when(TestimonialsCachedDataService.testimonials);

        } else {

          data.promise = $http.get('/testimonials', {
            cache: true
          })
            .then(function(result) {
              return result.data.testimonials;
            });

        }


        // This lets you use the service in a simple Angular-y way, just
        // wait for the promise to resolve internally, and let $digest
        // discover the data.
        data.promise
          .then(function(testimonials) {
            angular.copy(testimonials, data.testimonials);
          });

        return data;
      }
    ]);

})();