(function AppHomepageControllersCommunitiesCachedDataService() {
  "use strict";

  angular

    .module('modules.dataServices')

    /* global jsonCache */
    .service("AssetsCachedDataService", [
      function() {

        var data = {
          assets: []
        };

        if (typeof jsonCache === "undefined") {
          return data;
        }

        if (jsonCache.assets === undefined) {
          return data;
        }

        data.assets = jsonCache.assets;

        return data;
      }
    ]);

})();