angular.module('app.goGreen')
.controller('GoGreenCtrl', ['$scope', '$timeout', function ($scope, $timeout) {

  $scope.showCaption = function() {
    var sideNavCaption = $('.side-nav__caption');

    $timeout(function () {
      var slideCaption = $('.carousel-inner .item.active .caption').text(),
          mobileNavItem = $('.side-nav__list a.active').text();
      $(sideNavCaption).fadeOut(function() {
        $(sideNavCaption).text(slideCaption).fadeIn();
      });
      $('.toggle-nav__button span').text(mobileNavItem);
    }, 1900);
  }

  $scope.slides = [

    {
      title: "Exterior",
      caption: "We’ve partnered with Build It Green, a California non-profit that has written the green building standards which are rapidly being adopted by city governments throughout the entire state of California.",
      linkText: "EXPLORE INSIDE",
      link: 1,
      image: "/images/go-green/bkg-002.jpg",
      pins: [
        {
          id: 'ext-01',
          type: "natural-resources",
          caption: "Bike racks in garage",
          top: "73.5%",
          left: "47.5%",
          icon: "leaf-icon",
          title: "Natural Resources",
        },
        {
          id: 'ext-02',
          type: "healthier-homes",
          caption: "Low VOC paints, caulking & construction adhesives",
          top: "33%",
          left: "16%",
          icon: "heart-icon",
          title: "Healthier Homes",
        },
        {
          id: 'ext-03',
          type: "water-conservancy",
          caption: "High efficiency irrigation systems",
          top: "82.5%",
          left: "73.5%",
          icon: "water-icon",
          title: "Water Conservancy",
        },
        {
          id: 'ext-04',
          type: "energy-efficient",
          caption: "Optima® insulation",
          top: "18%",
          left: "63%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'ext-05',
          type: "technology",
          caption: "Ring doorbell kwikset touchscreen electronic entry",
          top: "70.5%",
          left: "88.5%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'ext-06',
          type: "technology",
          caption: "Wifi garage door opener liftmaster myq garages | keyless entry for garage door",
          top: "78%",
          left: "37.5%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'ext-07',
          type: "technology",
          caption: "Security pre-wire",
          top: "51%",
          left: "58.5%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'ext-08',
          type: "technology",
          caption: "Electric car charger outlet in garage 220v 40amp",
          top: "73.5%",
          left: "42%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'ext-09',
          type: "energy-efficient",
          caption: "Mitsubishi cooling & heating solutions maximize efficiency and minimize your power bill",
          top: "28.5%",
          left: "39%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
      ]

    },
    {
      title: "Living Space",
      caption: "Build It Green has developed the Green Point Rated® program, an objective home rating system based on energy efficiency, resource conservation, indoor air quality, water conservation and community development in California.",
      linkText: "EXPLORE KITCHEN",
      link: 2,
      image: "/images/go-green/bkg-003.jpg",
      pins: [
        {
          id: 'living-space-01',
          type: "technology",
          caption: "Nest | ecobee | honeywell thermostats",
          top: "45%",
          left: "21%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'living-space-02',
          type: "healthier-homes",
          caption: "High efficiency air conditioner environmentally responsible refrigerants",
          top: "39%",
          left: "56%",
          icon: "heart-icon",
          title: "Healthier Homes",
        },
        {
          id: 'living-space-03',
          type: "technology",
          caption: "Fiber optics - simple: a better wifi experience with high-speed internet",
          top: "36%",
          left: "45%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'living-space-04',
          type: "energy-efficient",
          caption: "Recessed led lighting throughout whole house",
          top: "12%",
          left: "56%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'living-space-05',
          type: "healthier-homes",
          caption: "Whole house fan",
          top: "13.5%",
          left: "78%",
          icon: "heart-icon",
          title: "Healthier Homes",
        },
        {
          id: 'living-space-06',
          type: "technology",
          caption: "Pre-wire for ceiling fans at living room",
          top: "13.5%",
          left: "62%",
          icon: "plug",
          title: "Technology",
        },
      ]

    },
    {
      title: "Kitchen",
      caption: "SummerHill has voluntarily undergone Build It Green GreenPoint Rated certification on many of its current communities.",
      linkText: "EXPLORE BEDROOM",
      link: 3,
      image: "/images/go-green/bkg-004.jpg",
      pins: [
        {
          id: 'kitchen-01',
          type: "energy-efficient",
          caption: "Undercabinet led lights in kitchen",
          top: "49.5%",
          left: "3%",
          icon: "electrical-icon",
          title: "Energy Efficient"
        },
        {
          id: 'kitchen-02',
          type: "healthier-homes",
          caption: "HVAC filter merv 8",
          top: "13.5%",
          left: "61.5%",
          icon: "heart-icon",
          title: "Healthier Homes",
        },
        {
          id: 'kitchen-03',
          type: "energy-efficient",
          caption: "Energy star appliances",
          top: "75%",
          left: "10.5%",
          icon: "electrical-icon",
          title: "Energy Efficient"
        },
        {
          id: 'kitchen-04',
          type: "energy-efficient",
          caption: "GE profile appliances with wifi wirelessly control functions from your smartphone",
          top: "66%",
          left: "27%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'kitchen-05',
          type: "natural-resources",
          caption: "Laundry – 110v and 220 outlets",
          top: "57%",
          left: "10.5%",
          icon: "leaf-icon",
          title: "Natural Resources"
        },
      ]

    },
    {
      title: "Bedroom",
      caption: "At its essence, green building is about building better, more durable structures in infill locations, a principle that has been at our core ever since our founding.",
      linkText: "EXPLORE BATHROOM",
      link: 4,
      image: "/images/go-green/bkg-005.jpg",
      pins: [
        {
          id: 'bedroom-01',
          type: "energy-efficient",
          caption: "Recessed led lighting",
          top: "13.5%",
          left: "72%",
          icon: "electrical-icon",
          title: "Energy Efficient"
        },
        {
          id: 'bedroom-02',
          type: "technology",
          caption: "USB receptacle combo",
          top: "40.5%",
          left: "46.5%",
          icon: "plug",
          title: "Technology",
        },
        {
          id: 'bedroom-03',
          type: "technology",
          caption: "CAT 6 wiring",
          top: "51%",
          left: "19.5%",
          icon: "plug",
          title: "Technology"
        },
        {
          id: 'bedroom-04',
          type: "energy-efficient",
          caption: "Pre-wire for ceiling fans at master bedroom",
          top: "13.5%",
          left: "28.5%",
          icon: "electrical-icon",
          title: "Energy Efficient"
        },
      ]

    },
    {
      title: "Bathroom",
      caption: "We invite you to join us on the path to a greener future.",
      linkText: "EXPLORE EXTERIOR",
      link: 1,
      image: "/images/go-green/bkg-006.jpg",
      pins: [
        {
          id: 'bathroom-01',
          type: "energy-efficient",
          caption: "Energy star bath fans",
          top: "13.5%",
          left: "53.5%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'bathroom-02',
          type: "energy-efficient",
          caption: "Navien tankless water heater with recirculation pump",
          top: "63%",
          left: "61.5%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'bathroom-03',
          type: "energy-efficient",
          caption: "Insulation on hot water pipes",
          top: "60%",
          left: "34.5%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'bathroom-04',
          type: "energy-efficient",
          caption: "Low flow & dual flush kohler® toilets",
          top: "66%",
          left: "3%",
          icon: "electrical-icon",
          title: "Energy Efficient",
        },
        {
          id: 'bathroom-05',
          type: "healthier-homes",
          caption: "Pre-plumb for water softener",
          top: "51%",
          left: "78%",
          icon: "heart-icon",
          title: "Healthier Homes",
        },
      ]

    },

  ]

}]);
