angular.module('app.journeyToHomeownership')
.controller('journeyToHomeownershipCtrl', ['$scope',
  function ($scope) {
    $scope.scrollToTop = function() {
      console.log('hi');
      document.querySelector('html').scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
}]);
