'use strict';
/* global $, angular */

angular.module("app.common").directive('mobileNav', ['device', function(device) {
	return {
        link: function(scope,element,attr){
            // remove top level nav links for touch devices

			if(device.check() == "phone" || device.check() == "ipad"){
			  $(".navbar-nav a.dropdown-toggle").attr({
			      href:"#", 
			  });
			  $('.navbar-nav a.dropdown-toggle').attr('data-toggle', 'dropdown');
			}

			// mobile open/close of sub menus
			if(device.check() == "phone") {
				$('li.dropdown, li.visible-xs').click(function() {
				  var slideDir = $(this).find(".dropdown-menu").is(':visible') ? 'slideUp' : 'slideDown';
				  $("li.dropdown.open").find(".dropdown-menu").velocity("slideUp");
				  $(this).find(".dropdown-menu").velocity(slideDir);
				});
			}
        }
    }
}]);