(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.homepage')
    .directive('playVideo', [function() {
      return {
        restrict: 'A',
        link: function(scope, elem, attr) {

          elem.on('click', function(e) {
            e.preventDefault();
            $("video#bgvid").get(0).play();
          });

        }
      };
    }]);

})();
