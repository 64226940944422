
  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('shStyledHouseholdSizeDropdown', function() {
      return {
        restrict: 'E',
        scope: {
          defaultPlaceholder: '@',
          selectedHouseholdSize: '=ngModel',
        },
        templateUrl: 'sh-styled-household-size-dropdown.html',
        controller: [ "$scope", function($scope) {
          $scope.selectedHouseholdSize = '';

          if(! $scope.defaultPlaceholder) {
              $scope.defaultPlaceholder = 'Household Size';
          }

          $scope.selectHouseholdSize = function selectHouseholdSize(householdSize) {
            $scope.defaultPlaceholder = householdSize.name;
            $scope.selectedHouseholdSize = householdSize.value;
          };

          $scope.householdSizes = [
              {
                  "value": 1,
                  "name": "1"
              },
              {
                  "value": 2,
                  "name": "2"
              },
              {
                  "value": 3,
                  "name": "3"
              },
              {
                  "value": 4,
                  "name": "4"
              },
              {
                  "value": 5,
                  "name": "5"
              },
              {
                  "value": 6,
                  "name": "6+"
              }
          ];

        }]
      };
    });
