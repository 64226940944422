'use strict';
/* global $, angular */

angular.module('app.common')
.directive('msGalleryShow', function() {

  return {
    templateUrl: 'ms-gallery-show',
    restrict: 'E',
    scope: {
      'items': '='
    },
    replace: true,
    link: function (scope, element, attrs) {
      var el = $(element[0]);
      var w;

      el.hide();

      scope.element = {};
      scope.selectedIndex = -1;

      w = scope.$watchCollection('items', function (items) {
        if (items.length > 0) {
          w();
          bindEvents();
        }
      });

      function bindEvents () {
        scope.$on('meGalleryShow:selectedIndex', function (_, selectedIndex) {
          scope.selectedIndex = selectedIndex;
          showSlideShow();
        });
      }

      function showSlideShow () {
        setActiveElement();

        el.fadeIn(200, function () {
          el.show();
        });

        $('body').addClass('body-no-scroll');
      }

      function setActiveElement () {
        scope.element = {
          prev: scope.items[scope.selectedIndex - 1] || void 0,
          active: scope.items[scope.selectedIndex],
          next: scope.items[scope.selectedIndex + 1] || void 0
        };
      }

      scope.btnPrev = function () {
        scope.selectedIndex -= 1;
        if (scope.selectedIndex < 0) {
          scope.selectedIndex = 0;
        }
        setActiveElement();
      };

      scope.btnNext = function () {
        scope.selectedIndex += 1;
        if (scope.selectedIndex > scope.items.length - 1) {
          scope.selectedIndex = scope.items.length - 1;
        }
        setActiveElement();
      };

      scope.btnClose = function () {
        el.fadeOut(100, function () {
          el.hide();
        });

        $('body').removeClass('body-no-scroll');
      };
    }
  };

});