(function(){
    "use strict";
    const MediaGalleryController = (function() {
        const mediaGallery = function($scope, $window, $timeout) {
            this.$scope = $scope;
            this.$window = $window;
            this.$timeout = $timeout;
            this.isLoading = false;
            this.scrollTimeout = undefined;
            this.videoTimeout = undefined;
            this.previousTab = '';
            this.activeTab = '';
            this.activeVTour = -1;
            this.hideVideo = this.hideVideo.bind(this);
            this.clearVideo = this.clearVideo.bind(this);
            if (this.defaultTab) {
                this.activeTab = this.defaultTab;
            }
            this.galleryFilteredCommunity = this.defaultCommunity;
            //console.log(this.defaultCommunity);
            //console.log(this.communities);
        };

        // Private
        mediaGallery.getVideoService = function(video) {
            let videoService = 'youtube';
            if (/matterport/.test(video)) {
                videoService = 'matterport';
            } else if (/vimeo/.test(video)) {
                videoService = 'vimeo';
            } else if (/ricohtours/.test(video)) {
                videoService = 'ricohtours';
            }
            return videoService;
        };

        // mediaGallery.prototype.changeCommunity = function(oldValue, newValue) {
        //     console.log(this.$scope.vm.fields.communities);
        //     //this.selectedCommunity = this.communities
        // };


        // Public
        mediaGallery.prototype.switchTab = function(tab) {
            this.previousTab = this.activeTab;
            this.activeTab = tab;
            $('.mg__tab-content').velocity('scroll', {
                duration: 1000,
                offset: -230,
                delay: 0,
                easing: 'ease-in-out'
            });
        };

        mediaGallery.prototype.toggleVirtualTour = function(id) {
            if (this.scrollTimeout) {
                this.$timeout.cancel(this.scrollTimeout);
            }
            this.activeVTour = (this.activeVTour === id) ? -1 : id;
            this.scrollTimeout = this.$timeout(
                this.scrollTo,
                60,
                true,
                this.activeVTour === -1
                    ? '.mg__vtour-item-' + id
                    : '.mg__vtours-residences.active'
            );
        };

        mediaGallery.prototype.scheduleCommunity = function(id) {
            this.$scope.selectedCommunity = id + '';
        };

        mediaGallery.prototype.showCommunityGallery = function(community) {
          this.selectedIndex = 0;

          this.gallery = community.carousel_images;

          $('#modal-gallery')
            .modal('show');
        };

        mediaGallery.prototype.showResidenceGallery = function(residence) {
          this.selectedIndex = 0;

          this.gallery = residence.assets;
          this.residence = residence;

          $('#modal-gallery')
            .modal('show');
        };

        mediaGallery.prototype.showVideo = function(video, videoService) {
            videoService = videoService || MediaGalleryController.getVideoService(video);
            this.video = video;
            this.videoService = videoService;
            $('#modal-video')
                .modal('show')
                .on('hidden.bs.modal', this.hideVideo);
        };

        mediaGallery.prototype.hideVideo = function() {
            if (this.videoTimeout) {
                this.$timeout.cancel(this.videoTimeout);
            }
            this.videoTimeout = this.$timeout(this.clearVideo, 60, true);
        };

        mediaGallery.prototype.clearVideo = function() {
            this.video = '';
            this.videoService = '';
            $('#modal-video').off('hidden.bs.modal', this.hideVideo);
        };

        mediaGallery.prototype.scrollTo = function(selector) {
            if ($(selector)[0]) {
                const headerOffset = $('.site-header').height();
                $('html, body').animate({
                    scrollTop: $(selector).offset().top - headerOffset
                }, 300, 'swing');
            }
        };

        return mediaGallery;
    })();

    MediaGalleryController.$inject = ['$scope', '$window', '$timeout'];

    angular
        .module('app.mediaGallery')
        .controller('MediaGalleryController', MediaGalleryController)
        .directive('mediaGallery', function() {
            return {
                restrict: 'A',
                scope: true,
                bindToController: {
                    communities: '=',
                    defaultTab: '@',
                    defaultCommunity: '@'
                },
                controller: MediaGalleryController,
                controllerAs: 'media'
            };
        });

})();
