(function() {
  "use strict";

  angular
    .module("app.homepage", [
      "angular-inview", // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
      "app.homepage.controllers",
      "app.homepage.directives",
      "app.homepage.services"
    ]);

  angular.module("app.homepage.controllers", [
      "app.homepage.directives",
      "app.homepage.services",
      'app.communityCard.directives',
      'modules.staticMap'
  ]);
  angular.module("app.homepage.directives", []);
  angular.module("app.homepage.services", []);

})();