(function() {
  "use strict";

  angular
    .module('app.microsite') // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
    .controller('micrositeFloorplansCtrl', ['$rootScope', '$scope', "CommunityDataService", "laravelParameters", "micrositeSubNavReveal", "MicrositeGalleryAssets",
      function($rootScope, $scope, CommunityDataService, laravelParameters, micrositeSubNavReveal, MicrositeGalleryAssets) {

        $scope.seoName = laravelParameters.segment(0);

        $scope.residences = [];

        $scope.selectGallery = function(residence) {
          angular.copy(residence.assets, MicrositeGalleryAssets.assets);
          angular.copy(residence, MicrositeGalleryAssets.residence);

          $('#modal-gallery').modal('show');
        };

        $scope.selectVideo = function(residence) {
          angular.copy(residence, MicrositeGalleryAssets.residence);

          $('#modal-video').modal('show');
        };

        CommunityDataService($scope.seoName)
          .promise
          .then(function(community){
            angular.copy(community.residences, $scope.residences);

            // reveal the floorplan nav if we got data to populate
            if (typeof $scope.residences !== "undefined") {
              if ($scope.residences.length > 0) {
                micrositeSubNavReveal.floorplans = true;
              }
            }
          });

        $scope.revealFaqPanel = function(){
          console.log("Show the FAQ!");
        };

        $scope.revealLendersPanel = function(){
          console.log("Show the Lenders!");
        };

        $scope.openFloorplan = function(residence) {
          $scope.is_mobile = /Android|webOS|iPhone|ipad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

          if (! $scope.is_mobile)
          {
            if (residence.interactive_floorplans)
            {
              window.open(residence.interactive_floorplans, '_blank');
            }
            else if (residence.hasInteractiveFloorplans)
            {
              $('#modal-interactive-floorplans').find('.modal-body')
                .empty()
                .load('/microsite/interactive-floorplans-modal/' + residence.community.id + '/' + residence.seo_name,
                  function() {
                });
              $('#modal-interactive-floorplans').modal('show');
            }
            else if (residence.floorplanPdf)
            {
              window.open(residence.floorplanPdf, '_blank');
            }
          }
          else if ($scope.is_mobile)
          {
            if (residence.interactive_floorplans)
            {
              window.open(residence.interactive_floorplans, '_blank');
            }
            else if (residence.floorplanPdf)
            {
              window.open(residence.floorplanPdf, '_blank');
            }
          }

          clicky_custom.goal = {
            name: 'Viewed Floorplan - Smartplans'
          };

          // Log action in clicky
          clicky.goal('1987');      // Logs a "quick-signup" goal hit in clicky

          // Fire off a Google Analytics goal hit
          ga('create', 'UA-846906-1');
          ga(function(tracker) {
            ga('send', 'pageview', '/ajax-goals/floorplan-view');

            ga('send', 'event', 'Floorplan', 'click', residence.community.name + ' - ' + residence.name, 1);
            console.log(residence.community.name + ' - ' + residence.name)
          });
        };



        $('#modal-interactive-floorplans').on('click', '.btn-select-another-plan .dropdown-menu li', function() {
          var data_url = $(this).data('url');

          $('#modal-interactive-floorplans').find('.modal-body').empty()
          .load(data_url,
            function() {
          });
        });

        $('#modal-interactive-floorplans').on('click', '.select-an-elevation ul li a', function() {
          $('#modal-interactive-floorplans .modal-body > .interactive-floorplans').append('<div class="modal-backdrop-custom"></div>');
          $('.modal-custom').show();
          $('a.close').hide();
        });

        $('#modal-interactive-floorplans').on('click', '.btn-close', function() {
          $('.modal-backdrop-custom').remove();
          $('.modal-custom').hide();
          $('a.close').show();
        });

      }
    ])
  ;

})();
