'use strict';
/* global $, angular */

angular.module('app.portfolio')
.directive('readMore', ['device', function(device, $index, $scope) {
  return {
    restrict: 'A',
    link: function ($scope, elem, attr, index) {
      var showMore = $('.content a.footer');
      var panel = $('.content');
      //console.log(showMore);


      if(!$(panel).hasClass('is-open')){
          $('.portfolio-text-wrap').addClass('ellipsis');
        }
      if(device.check() == "phone") {
        $('.portfolio-text-wrap').css('height', '150px');
      } else {
        $('.portfolio-text-wrap').css('height', '200px');
      }

        $scope.togglePanel = function ($event) {
             //alert($event.target.id);
             if($($event.target).text() == 'READ LESS') {
              $($event.target).text('READ MORE');
              $($event.target).removeClass("opened");
              } else {
                $($event.target).text('READ LESS');
                $($event.target).addClass("opened");
              }
              //$($event.target).closest('.community').toggleClass('opened');
              var panel = $($event.target).closest('.content'),
                  content = $('.content');
                 // console.log(panel);
                 // console.log(content);

                //$(content).removeClass("is-open");
                //$(content).css('height', '360px');
                //console.log("test");
              $(content).not(panel).removeClass("is-open");
              if(device.check() == "phone") {
                $(content).not(panel).css('height', '275px');
              } else {
                $(content).not(panel).css('height', '360px');
              }
              //$(content).not(panel).find(showMore).text('READ MORE');
              $(showMore).not($event.target).text('READ MORE');
               $(showMore).not($event.target).removeClass("opened");
              //$(content).removeClass("is-open");
              $(panel).toggleClass("is-open");

              var textHeight = $(panel).find('.portfolio-text-wrap')[0].scrollHeight + 175;

              if ($($event.target).closest(panel).hasClass('is-open')) {
                $($event.target).closest(panel).css('height', textHeight);
                $('.portfolio-text-wrap').addClass('ellipsis'); 
                $(panel).find('.portfolio-text-wrap').removeClass('ellipsis');
              } else {
                if(device.check() == "phone") {
                  $($event.target).closest(content).css('height', '275px');
                } else {
                  $($event.target).closest(content).css('height', '360px');
                }
                $('.portfolio-text-wrap').addClass('ellipsis');

              }
          }
    
    }
  };
}]);