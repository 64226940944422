(function() {
  "use strict";

  angular
    .module('app.microsite') // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
    .controller('HeroCardCtrl', ['$scope', "$location", "CommunityDataService", "laravelParameters",
      function($scope, $location, CommunityDataService, laravelParameters) {
        
        $scope.seoName = laravelParameters.segment(0);

        CommunityDataService($scope.seoName)
          .promise
          .then(function(community){
            $scope.community = community;
          })
        ;

      }
    ])
  ;

})();
