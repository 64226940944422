(function () {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("AssetsDataService", ['$http', '$q', 'AssetsCachedDataService',
      function($http, $q, AssetsCachedDataService) {

        var data = {
          assets: [],
          promise: null
        };

        if (AssetsCachedDataService.assets.length > 0) {

          // Wrap the cached data in a promise to provide a consistent api
          data.promise = $q.when(AssetsCachedDataService.assets);

        } else {

          data.promise = $http.get('/asset', {
            cache: true
          })
            .then(function(result) {
              return result.data.assets;
            });

        }

        // This lets you use the service in a simple Angular-y way, just
        // wait for the promise to resolve internally, and let $digest
        // discover the data.
        data.promise
          .then(function(assets) {
            angular.copy(assets, data.assets);
          });

        return data;
      }
    ]);

})();