
  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('shStyledIncomeRangeDropdown', function() {
      return {
        restrict: 'E',
        scope: {
          defaultPlaceholder: '@',
          selectedIncomeRange: '=ngModel',
        },
        templateUrl: 'sh-styled-income-range-dropdown.html',
        controller: [ "$scope", function($scope) {
          $scope.selectedIncomeRange = '';

          if(! $scope.defaultPlaceholder) {
              $scope.defaultPlaceholder = 'Income Range';
          }

          $scope.selectIncomeRange = function selectIncomeRange(incomeRange) {
            $scope.defaultPlaceholder = incomeRange.name;
            $scope.selectedIncomeRange = incomeRange.value;
          };

          $scope.incomeRanges = [
              {
                  "value": "<$50,000",
                  "name": "< $50,000"
              },
              {
                  "value": "$50,000-$100,000",
                  "name": "$50,000 - $100,000"
              },
              {
                  "value": "$101,000-$150,000",
                  "name": "$101,000 - $150,000"
              },
              {
                  "value": "$151,000-$200,000",
                  "name": "$151,000 - $200,000"
              },
              {
                  "value": "$201,000-$250,000",
                  "name": "$201,000 - $250,000"
              },
              {
                  "value": "$251,000-$300,000",
                  "name": "$251,000 - $300,000"
              },
              {
                  "value": ">$301,000",
                  "name": "$301,000 +"
              }
          ];

        }]
      };
    });
