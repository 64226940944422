(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.microsite')
    .service('micrositeSubNavReveal', [
      function() {
        
        var navElements = {
          'welcome': true,
          'floorplans': false,
          'site-plan': false,
          'features': false,
          'local-amenities': true,
          'contact': false,
          'faqs': true,
          'lenders': true
        }

        return navElements;
      }
    ])
  ;

})();
