(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.microsite')
    .controller('MicrositeFloorplanVideoCtrl', ['$scope', 'MicrositeGalleryAssets',
      function($scope, MicrositeGalleryAssets) {

        $scope.residence = MicrositeGalleryAssets.residence;

        $scope.playerVars = {
          autoplay: 1
        };

        var videoFunc ='';
        var outerDiv = document.getElementById('modal-video');

        $('#modal-video').on('hide.bs.modal', function (e) {
          var youtubeIframe = outerDiv.getElementsByTagName('iframe')[0].contentWindow;
          videoFunc = 'pauseVideo';
          youtubeIframe.postMessage('{"event":"command","func":"' + videoFunc + '","args":""}', '*');
        });

      }
    ])
  ;

})();