(function() {
  "use strict";

  /**
   *
   *  Here is how to use this:
   *
   *  $scope.community = CommunityDataService($scope.seo_name).community;
   *
   *  or
   *
   *  CommunityDataService($scope.seo_name)
   *    .promise
   *    .then(function(community){
   *      $scope.community = community;
   *    });
   *
   */


  angular

    .module('modules.dataServices')

    .factory("CommunityDataService", ['$http', '$q', 'CommunityCachedDataService',
      function($http, $q, CommunityCachedDataService) {

        // singleton cache
        var cache = {};

        // return this function that we can actually use
        return function(seo_name){

          var normalized_seo_name = seo_name + '';

          if (normalized_seo_name.indexOf('/') > -1) {
            normalized_seo_name = normalized_seo_name.split('/')[1];
          }

          if (typeof cache[seo_name] != "undefined") {
            return cache[seo_name];
          }

          var data = {
            community: null,
            promise: null
          };

          var cachedBySeoName = function() {
            if (CommunityCachedDataService.community) {
              if (CommunityCachedDataService.community.seo_name === seo_name) {
                data.community = CommunityCachedDataService.community;
                data.promise = $q.when(CommunityCachedDataService.community);
                return true;
              }
            }
          };

          var fetchOverHttp = function() {
            data.promise = $http.get('/community/' +  normalized_seo_name, {
              cache: true
            })
              .then(function(result) {
                return result.data.community;
              })

              // This lets you use the service in a simple Angular-y way, just
              // wait for the promise to resolve internally, and let $digest
              // discover the data.
              .then(function(community) {
                data.community = community;
                return community;
              });

            return true;
          };

          // build the return data
          var result = cachedBySeoName(seo_name);

          if (result) {
            return data;
          }

          fetchOverHttp(seo_name);

          // don't forget to set the singleton cache
          cache[seo_name] = data;
          return data;

        };
      }
    ]);

})();
