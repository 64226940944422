(function() {
  "use strict";

  angular
    .module('app.communityList')
    .controller('CommunityListCtrl', ['$scope', 'CommunitiesDataService',
      function($scope, CommunitiesDataService) {

        CommunitiesDataService
          .promise
          .then(function(communities) {
            $scope.communities = communities;
          });

      }
    ])
  ;

})();
