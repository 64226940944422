var comm = null;
(function() {
  "use strict";

  angular
    .module('app.microsite') // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
    .controller('MicroSiteCtrl', ['$rootScope', '$scope', '$timeout', "$location", "CommunityDataService", "laravelParameters", "MicrositeGalleryAssets",
      function($rootScope, $scope, $timeout, $location, CommunityDataService, laravelParameters, MicrositeGalleryAssets) {

        $scope.selectedResidence = {};

        $scope.showAdditionalInfo = function(residence) {
          $scope.selectedResidence = residence;

          $('#modal-additional-info').modal('show');
        };

        $scope.viewTheGallery = function() {
          $('#modal-gallery').modal('show');
        };

        $scope.seoName = laravelParameters.segment(0);



        $scope.communityPromise = CommunityDataService($scope.seoName).promise;

        $scope.reverse = false;

        //$scope.toggleText = true;

        $scope.showCollectionsModal = function(collection) {
          $rootScope.collectionsModal = collection;
        }

        $scope.communityPromise
          .then(function(community){

            $scope.community = community;
            $scope.features = $scope.community.features;

            // custom order for locale at state street community
            if ($scope.community.id === 10) {
              $scope.reverse = true;
            }

            angular.copy(community.carousel_images, MicrositeGalleryAssets.assets);

          });


        $scope.closed = true;

        $scope.gallery = [];

        $scope.autoplay = true;
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        if (!mediaQuery || mediaQuery.matches) {
            $scope.autoplay = false;
            // User prefers reduced motion. Do not add `autoplay=1` to the params
        }

        $(function() {
          $("#micrositeCarousel").addClass("fade-in");
          $('.carousel-control').addClass('slide-in');
          $(".carousel-indicators").addClass("fade-in");
          $(".box").addClass("flip-in");
          $("img.down-arrow").addClass("fade-in-carousel-arrow");
        });

        // The function actually applying the offset
        function offsetAnchor() {
          if (location.hash.length !== 0) {
            window.scrollTo(window.scrollX, window.scrollY - 130);
          }
        }

        // This will capture hash changes while on the page
        $(window).on("hashchange", function() {
          offsetAnchor();
        });

        // This is here so that when you enter the page with a hash,
        // it can provide the offset in that case too. Having a timeout
        // seems necessary to allow the browser to jump to the anchor first.
        window.setTimeout(function() {
          offsetAnchor();
        }, 1);

        $scope.toggle = true;

        $scope.$watch('toggle', function() {
          $scope.toggleText = $scope.toggle ? 'Show Filters +' : 'Hide Filters -';
        })

        var controller = new ScrollMagic.Controller();

        // create animation
        var tween = TweenMax.to('.carousel-arrow-down', 0.5, {
          marginBottom: 0,
          //bottom: 0,
        //opacity: 0,
        });


        // Create the Scene and trigger when visible with ScrollMagic
        const carouselDownElem = angular.element('#carousel-arrow-down')[0];
        console.log(carouselDownElem);
        if (carouselDownElem) {
          var scene = new ScrollMagic.Scene({
            triggerElement: '#carousel-arrow-down',
            //triggerHook: 'onEnter',
            duration: 200,
            offset: 150, /* offset the trigger 150px below #scene's top */
          })
            .setTween(tween)
            .addTo(controller);
        }

        $scope.activeTab = function(tab) {
          return '/' + tab === $location.path();
        };

        $scope.communities = [
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'null',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          },
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'null',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          },
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'null',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          },
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'sold-out',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          },
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'null',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          },
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'null',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          },
          {
            name: 'RESIDENCE 2A',
            price: 995644,
            beds: 3,
            baths: 2.5,
            sqft: 1568,
            status: 'null',
            ifGallery: true,
            ifVideo: true,
            ifFloorplan: true
          }
        ];

        $scope.amenities = [
          {
            name: 'Stratford School',
            address: '820 West McKinley Avenue Sunnyvale, CA 94086',
          },
          {
            name: 'Oak Avenue Elementary School',
            address: '1501 Oak Avenue Los Altos, CA 94024',
          },
          {
            name: 'William Faria Elementary School',
            address: '10155 Barbara Lane Cupertino, CA 95014-2901',
          },
        ];

        $scope.watchCmsVideo = function(videoNumber) {
          var vid = 'video_url';
          if(videoNumber && videoNumber > 1) {
            vid = 'video_url_'+videoNumber
          }
          var videoService = 'youtube';
          if (/vimeo/.test($scope.community[vid])) {
            videoService = 'vimeo';
          }
          angular.copy({ videoService: videoService, video_url: $scope.community[vid]}, MicrositeGalleryAssets.residence);

          $('#modal-video').modal('show');
        };

        $scope.isMortgageCalcOpen = false;
        $scope.mortgageCalcOptions = {
          price: 0,
          hoa: 0,
          taxes: 0,
        };
        $rootScope.selectMortgageCalc = function(residence) {
          $scope.mortgageCalcOptions.price = residence.price;
          $scope.mortgageCalcOptions.hoa = residence.community ? residence.community.est_homeowner_fees : 0;
          $scope.mortgageCalcOptions.taxes = residence.community ? residence.community.est_property_taxes : 0;
          $scope.isMortgageCalcOpen = true;
          $timeout(function () {
            $('#modal-mortgage-calculator').modal('show');
          }, 0);
        };
        $scope.selectMortgageCalcCommunity = function (community) {
          $scope.mortgageCalcOptions.price = community.price;
          $scope.mortgageCalcOptions.hoa = community.est_homeowner_fees || 0;
          $scope.mortgageCalcOptions.taxes = community.est_property_taxes || 0;
          $scope.isMortgageCalcOpen = true;
          $timeout(function () {
            $('#modal-mortgage-calculator').modal('show');
          }, 0);
        }

      }
    ])
  ;

})();
