angular.module('app.city').controller('CityCtrl', [
    '$scope',
    '$http',
    '$filter',
    'dummyDataService',
    'CommunitiesDataService',
    function ($scope, $http, $filter, dummyDataService, CommunitiesDataService) {
        $scope.hears = dummyDataService.newHears();
        $scope.contacts = dummyDataService.contacts();
        $scope.communities = [];

        CommunitiesDataService.promise.then(function (communities) {
            _.each($filter('filter')(communities, {
                availability: '!presale'
            }), function (community) {
                $scope.communities.push({
                    key: community.id,
                    value: community.name,
                    collections: community.collections,
                    subregion_id: community.subregion_id
                });
            })
        });

        $scope.signup = {};
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'cityLanding';

        $scope.hideForm = false;
        $scope.showMessage = false;
        $scope.showLoader = false;
        $scope.triedSubmit = false;

        $scope.checkFormValidation = function (form) {
            $scope.triedSubmit = true;

            if (!form.$valid) {
                return;
            }

            processForm();
        };

        function processForm() {
            $scope.hideForm = true;
            $scope.showLoader = true;
            $http({
                method: 'POST',
                url: '/form/to-log-file',
                data: $.param($scope.signup),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                $scope.showLoader = false;
                if (data.status === 'success') {
                    $scope.message = data.message;
                    $scope.showMessage = true;
                } else {
                    $scope.message = data.message;
                    $scope.showMessage = true;
                }
            })
        }


    }
]);
