angular.module('app.philanthropy')
.controller('PhilanthropyCtrl', ['$scope','chunkData', function ($scope,chunkData) {

	$scope.gp = [
    "AACI",
    "Amyotrophic lateral sclerosis (ALS)",
    "BHS Burlingame High School Excellence Fund",
    "Bridge Housing Corporation",
    "Burlingame Community Education Foundation",
    "Burlingame High School Excellence Fund",
    "California High School of San Ramon",
    "Cancer Point",
    "City of San Bruno Youth Enrichment Scholarship Program",
    "The Construction Industry Workforce Initiative",
    "Food Bank or Contra Costa &amp; Solano",
    "Fremont Education Foundation",
    "Fremont Festival of The Arts",
    "Hispanic Foundation Of Silicon Valley",
    "Home Aid Northern California",
    "Home Aid",
    "Housing Trust",
    "Los Gatos Education Foundation",
    "Midpeninsula Regional Open Space District",
    "Milpitas Community Educational Endowment",
    "Mission City Community Fund",
    "Mission City Community Fund (MCCF)",
    "Moraga Education Foundation",
    "Peninsula Family Service",
    "Rebuilding Together",
    "Rebuilding Together Peninsula",
    "Recovery Cafe; San Jose",
    "San Jose Day Nursery",
    "San Jose Fire Museum",
    "San Jose Police Foundation",
    "San Jose Stage Company",
    "Save Mount Diablo",
    "Sentinels of Freedom",
    "Sentinels of Freedom Scholarship Foundation",
    "The Sequoia Awards",
    "Silicon Valley Bicycle Coalition",
    "Silicon Valley Education Foundation",
    "Silicon Valley Education Foundation - 49ers STEM Leadership Institute",
    "Silicon Valley Leadership Group",
    "Silicon Valley Turkey Trot",
    "South County Youth Shoot",
    "South San Francisco Friends of the Library",
    "TEDx Los Gatos",
    "TEDx San Francisco",
    "Valley Children's Museum"
	]

	$scope.groups = chunkData.split($scope.gp, 2);

}]);