;(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("app")
    .controller("NewCarouselCtrl", [
      '$scope',
      '$document',
      '$window',
      '$timeout',
      function ($scope, $document, $window, $timeout) {
        var prefersReducedMotionQuery = $window.matchMedia('(prefers-reduced-motion: reduce)');
        var prefersReducedMotion = prefersReducedMotionQuery.matches;

        var doc = $document.get(0);
        var slides = [];
        var active = 0;
        var lastActive = 0;
        var cancelPlayTimeout = null;

        $scope.isPlaying = false;

        function updateSlide(slide, i) {
          if (slide.classList.contains('last-active')) {
            slide.classList.remove('last-active');
          }
          if (slide.classList.contains('active')) {
            slide.classList.add('last-active');
            slide.classList.remove('active');
            lastActive = i;
          }
          if (i === active) {
            slide.classList.add('active');
          }
        }

        function rotateSlides() {
          active = (active === slides.length - 1)
            ? 0
            : active + 1;
          slides.forEach(updateSlide);
          if ($scope.isPlaying) {
            cancelPlayTimeout = $timeout(rotateSlides, 7000);
          }
        }

        $scope.togglePlaying = function () {
          if ($scope.isPlaying) {
            $scope.isPlaying = false;
            cancelPlayTimeout = $timeout(rotateSlides, 7000);
          } else {
            $scope.isPlaying = true;
            if (cancelPlayTimeout) {
              cancelPlayTimeout();
            }
          }
        };

        this.$postLink = function () {
          var slideElems = doc.querySelectorAll('.ssh-hero__carousel-item');

          slides = Array.from(slideElems);

          lastActive = slides.length - 1;
          slides[active].classList.add('active');
          slides[lastActive].classList.add('last-active');

          if (!prefersReducedMotion) {
            $scope.isPlaying = true;
            cancelPlayTimeout = $timeout(rotateSlides, 7000);
          }
        };
      }
    ])
    .controller("CarouselCtrl", ['$scope',
      function($scope) {

        var carousel = document.querySelector('.carousel');

        if (carousel) {
          var $carousel = $(carousel);

          $carousel.carousel({
            interval: 7000
          });

          console.log('carousel should be carouselling');

          $scope.swipeLeft = function(){
            console.log("swiped next");
            $carousel.carousel('next');
          }

          $scope.swipeRight = function(){
            console.log("swiped prev");
            $carousel.carousel('prev');
          }
        }
      }
    ])
  ;

})();
