// (function() {

  'use strict';
  /* global $, angular */

  angular
    .module("app.common")

    .directive('scrollspy', ['$location', '$document', function($location, $document) {

      return function(scope, elem, attrs) {

        //SCROLL SPY
        $('body').scrollspy({
          target: "ul.sub-nav-left",
          offset: 100 
        });

        var scrollToTarget = function(target) {
          var offset = 90,
              beforeFixedOffset = 390;

          console.log("scrollToTarget: ", target);

          // if (target === '#welcome') {
          //   offset = 14;
          // }

          var scrollTo = $(target).offset().top - offset;
          var scrollToBeforeFixed = $(target).offset().top - beforeFixedOffset;

          // if (target === '#micrositeCarousel') {
          //   scrollTo = 0;
          // }

          $location.url(target.substring(1));

          if($(".sub-nav").hasClass("affix")) {
              $('html, body').animate({
                scrollTop: scrollTo
              }, 1000);

          } else if ($("body").hasClass("upcoming")){
              $('html, body').animate({
                scrollTop: scrollTo
              }, 1000);
          } else {

            $('html, body').animate({
            scrollTop: scrollToBeforeFixed
          }, 1000);

          }

          // $('html, body').animate({
          //   scrollTop: scrollTo
          // }, 1000);
        };

        //SCROLL TO
        elem.find('a:not(".accordion")').on('click', function(event) {

          // event.preventDefault();

          //console.log("Clicked");

          var target = $(this).attr('href');

          // Floorplans and Available Homes are tabs on the same 
          // scroll anchor, so just switch tabs instead of scroll.
          if (target === '#floorplans') {
            $('.li-floorplans.two').removeClass('active');
            $('#floorplans .nav-tabs .item-floor-plans').click();
          } else if (target === '#available-homes') {
            $('.li-floorplans.one').removeClass('active');
            $('#floorplans .nav-tabs .item-available-homes').click();
          }

          scrollToTarget(target);
        });

        //AFFIX EVENT
        // $('.affix-trigger').on('affix.bs.affix', function() {
        //   $('.navbar-fixed-top').velocity({
        //     marginTop: -150
        //   }, 500, 'easeInQuad');
        //   $('#microsite-secondary').addClass('fixed').velocity({
        //     marginTop: -180
        //   }, 0, 'easeInQuad');
        // });
        // $('.affix-trigger').on('affixed-top.bs.affix', function() {
        //   $('.navbar-fixed-top').velocity({
        //     marginTop: 0
        //   }, 300, 'easeOutQuad');
        //   $('#microsite-secondary').removeClass('fixed').velocity({
        //     marginTop: -30
        //   }, 200, 'easeOutQuad');
        // });

        //SCROLLSPY EVENT
        $('.sub-nav').on('activate.bs.scrollspy', function() {
          scope.$apply(function() {
            // var uri = elem.find('li.active a').attr('href');
            // $location.path(uri.substring(1), false);
          });
        });

      };
    }]);


// trying new approach

// 'use strict';
// /* global $, angular */

// angular
//   .module("app.common")
//   .directive('scrollspy', ['scrollMagic', '$location','device','$timeout', function(scrollMagic, $location, device, $timeout){
//     return {
//         restrict: 'A',
//         link: function(scope, elem, attrs) {

//             //scrollmagic scrollspy
//             elem.find('li').each(function(){

//               scrollMagic.scrollSpy($(this).attr('target'), 'active', 'onLeave', -178);

//               $(this).bind('click', function(){
//                 scrollto($(this).attr('target'));
//               })

//             });

//             //scroll to section on window load
//             id = $location.path().split('/');
//             baseUrl = id[1];
//             if(id[2]){
//               $timeout(function(){
//                 scrollto(id[2]);
//               },0)
//             }

//             //location replace on scroll
//             scope.$on('scrollspyTriggered', function(event, args) {
//                 section = '/' + args.url;
//                 if(args.url == undefined){
//                   section = '';
//                 }

//                 $location.path(baseUrl + section, true);
//                 scope.$apply();
//             });

//             function scrollto(elemTgrt, offset){
//               var offset = attrs.dskOffset;
//               if (device.check() == 'phone') {
//                 offset = attrs.mblOffset;
//               }

//               $('html, body').animate({
//                 scrollTop: $('#' + elemTgrt).offset().top - offset
//               }, 1000);

//             }
//         }
//     }
// }]);

// })();