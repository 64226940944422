angular.module('app.companyProfile')
.controller('companyProfileBioModalCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {

	"use strict";
	//$scope.profile = $scope.$parent.profile;
	//console.log("Modal scope ", $scope.profile); 

	$scope.close = function(){
        $modalInstance.close(); 
    };

}]);