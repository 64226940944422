(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    /* global jsonCache */
    .service("GalleriesCachedDataService", [
      function() {

        var data = {
          galleries: false
        };

        if (typeof jsonCache === "undefined") {
          return data;
        }

        if (typeof jsonCache.galleries === "undefined") {
          return data;
        }

        data.galleries = jsonCache.galleries;

        return data;
      }
    ]);

})();