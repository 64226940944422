"use strict";

angular.module('app.homepage.controllers')
.controller('HomePageCtrl',['$scope', function ($scope) {

	$scope.whereWeBuild = [
		{
			name: 'arques place',
			location: 'Sunnyvale, CA',
			prices: 'Prices from the high $1,000,000s',
			image: '/images/homepage/where-we-build-3.png'
		},
		{
			name: 'mission place',
			location: 'Fremont, CA',
			prices: 'Prices from the high $900,000s',
			image: '/images/homepage/where-we-build-4.png'
		},
		{
			name: 'Sorellas',
			location: 'Los Gatos, CA',
			prices: 'Prices from the high $3,000,0000s',
			image: '/images/homepage/where-we-build-2.png'
		},
		{
			name: 'harvest court',
			location: 'Moraga, CA',
			prices: 'Prices to be determined',
			image: '/images/homepage/where-we-build-1.png',
			commingSoon: true,
		},
		{
			name: 'Bellavista',
			location: 'Moraga, CA',
			prices: 'Prices from the high $1,000,000s',
			image: '/images/homepage/where-we-build-1.png',
			commingSoon: true
		},
		{
			name: 'Andares',
			location: 'Pleasanton, CA',
			prices: 'Prices from the high $1,000,000s',
			image: '/images/homepage/where-we-build-1.png',
			commingSoon: true
		},
	];

  $scope.displayNews = function(inview, inviewpart, event) {
    if (inview) {
      $('section.news .section-wrap').addClass('fade-in');
    }
  };

	$scope.displayWhereWeBuild = function(inview, inviewpart, event) {
		if (inview) {
			$('section.where-we-build .section-wrap').addClass('fade-in');
			$('.parallax').addClass('slide-left');
		}
	};

	$scope.displayGallery = function(inview, inviewpart, event) {
		if (inview) {
			$('section.gallery .section-wrap').addClass('fade-in');
		}
	};

	$scope.displayTestimonials = function(inview, inviewpart, event) {
		if (inview) {
			$('.testimonials .section-wrap').addClass('fade-in');
		}
	};

	$scope.displayDesignStudio = function(inview, inviewpart, event) {
		if (inview) {
			$('.design-studio .section-wrap').addClass('fade-in');
			$('.slide-in-left').addClass('slide-it');
			$(".parallax-wrap").addClass("fade-in");
		}
	};

  $scope.displayLetsConnect = function(inview, inviewpart, event) {
    if (inview) {
      $('.lets-connect .section-wrap').addClass('fade-in');
      $('.slide-in-left').addClass('slide-it');
      $(".parallax-wrap").addClass("fade-in");
    }
  };

$(function() {
  // Init Controller

  var controller = new ScrollMagic.Controller();

  // create animation
  var tween = TweenMax.staggerFromTo('.animation', 0.8,
  {

        opacity: 0,
        transform: 'translateY(70px)',
  },
    {
        opacity: 1,
        transform: 'translateY(0px)'
    },
    0.4 /* Stagger duration */
);

    // Create the Scene and trigger when visible with ScrollMagic
	var scene = new ScrollMagic.Scene({
    triggerElement: '#scene',
    //triggerHook: 'onEnter',
    offset: 150, /* offset the trigger 150px below #scene's top */
    //duration: "100%"
	})
	.setTween(tween)
	.addTo(controller);

var reverse = scene.reverse();
scene.reverse(false);

  var tween2 = TweenMax.staggerFromTo('.pop-in', 1,
  {

        opacity: 0,
        scale: 2,
        immediateRender: false,
  },
    {
        opacity: 1,
        scale: 1,
        immediateRender: false
    },
    0.4 /* Stagger duration */
);


	var scene2 = new ScrollMagic.Scene({
    triggerElement: '#scene-2',
    //triggerHook: 'onEnter',
    offset: 0, /* offset the trigger 150px below #scene's top */
    //duration: "100%"
	})

.setTween(tween2)
//.setClassToggle(".parallax", "slide-left")
.addTo(controller);

var reverse2 = scene2.reverse();
scene2.reverse(false);

var fadeController = new ScrollMagic.Controller();
var scene3 = new ScrollMagic.Scene({
		triggerElement: '#scene-design-studio',
		//triggerHook: 'onEnter',
		offset: 150,
		//duration: "100%"
})
//.setClassToggle(".design-studio .title", "fadeInLeft")
.addTo(fadeController);


var tweenGallery = TweenMax.staggerFromTo('.left-to-right', 0.7,
  {

        opacity: 0,
        scale: 0.9,
  },
    {
        opacity: 1,
        scale: 1
    },
    0.4 /* Stagger duration */
);

// var scene3 = new ScrollMagic.Scene({
// 		triggerElement: '.gallery-container',
// 		//triggerHook: 'onEnter',
// 		offset: 0, /* offset the trigger 150px below #scene's top */
// 		//duration: "100%"
// })
// .setTween(tweenGallery)
// .addTo(controller);
var reverse3 = scene3.reverse();
scene3.reverse(false);

// var scene4 = new ScrollMagic.Scene({
// 		triggerElement: '.design-studio',
// 		//triggerHook: 'onEnter',
// 		offset: 250,
// 		//duration: "100%"
// })

// .setClassToggle(".parallax-wrap", "fade-in")
// .addTo(controller);
// var reverse4 = scene4.reverse();
// scene4.reverse(false);

var testimonialsTween = TweenMax.staggerFromTo('.fade-up-in', 0.6,
  {

        opacity: 0,
        transform: 'translateY(100px)',
  },
    {
        opacity: 1,
        transform: 'translateY(0px)'
    },
    0.4 /* Stagger duration */
);

var scene5 = new ScrollMagic.Scene({
		triggerElement: '.testimonials',
		//triggerHook: 'onEnter',
		offset: 0,
		//duration: "100%"
})

.setTween(testimonialsTween)
.addTo(controller);
var reverse5 = scene5.reverse();
scene5.reverse(false);

// var scene6 = new ScrollMagic.Scene({
// 		triggerElement: '.parallax5',
// 		//triggerHook: 'onEnter',
// 		offset: 250,
// 		//duration: "100%"
// })

// .setClassToggle(".slide-in-left", "slide-it")

// .addTo(controller);
// var reverse6 = scene6.reverse();
// scene6.reverse(false);

});

var controller = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", offset: 0, duration: "200%"}});

	// build scenes
	// new ScrollMagic.Scene({triggerElement: ".parallax1"})
	// .setTween(".parallax1 > .carousel-inner", {y: "100%", ease: Linear.easeNone})
	// .addTo(controller);

	// new ScrollMagic.Scene({triggerElement: ".parallax1"})
	// .setTween(".parallax1 .caption", {y: "100%", ease: Linear.easeNone})
	// .addTo(controller);

	// var whereWeBuildController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", offset: 0, duration: "450%"}});

	// new ScrollMagic.Scene({triggerElement: ".parallax2"})
	// .setTween(".parallax2 > div.container-fluid", {y: "100%", ease: Linear.easeNone})
	// .addTo(whereWeBuildController);


	// var galleryController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", offset: 100, duration: "800%"}});

	// new ScrollMagic.Scene({triggerElement: ".parallax3"})
	// .setTween(".parallax3 > .container", {y: "100%", ease: Linear.easeNone})
	// .addTo(galleryController);

	// new ScrollMagic.Scene({triggerElement: ".parallax4"})
	// .setTween(".parallax4 > div.carousel-inner", {y: "100%", ease: Linear.easeNone})
	// //.setClassToggle("#testimonialsCarousel", "fade-in")
	// .addTo(controller);

	// var designStudioController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", offset: 0, duration: "350%"}});

	// new ScrollMagic.Scene({triggerElement: ".parallax5"})
	// .setTween(".parallax5 > .parallax-wrap", {y: "100%", ease: Linear.easeNone})
	// .addTo(designStudioController);



// (function() {

// 	//Function to animate slider captions
// 	function doAnimations( elems ) {
// 		//Cache the animationend event in a variable
// 		var animEndEv = 'webkitAnimationEnd animationend';

// 		elems.each(function () {
// 			var $this = $(this),
// 				$animationType = $this.data('animation');
// 			$this.addClass($animationType).one(animEndEv, function () {
// 				$this.removeClass($animationType);
// 			});
// 		});
// 	}

// 	//Variables on page load
// 	var $myCarousel = $('#homepageCarousel'),
// 		$firstAnimatingElems = $myCarousel.find('.item.first').find("[data-animation ^= 'animated']");

// 	//Initialize carousel
// 	$myCarousel.carousel();

// 	//Animate captions in first slide on page load
// 	doAnimations($firstAnimatingElems);

// 	//Pause carousel
// 	$myCarousel.carousel('pause');


// 	//Other slides to be animated on carousel slide event
// 	$myCarousel.on('slide.bs.carousel', function (e) {
// 		var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
// 		$animatingElems.addClass("slid");
// 		doAnimations($animatingElems);
// 	});

// })();

// Add class to carousel navigation on page load

$(function() {
	// $("#homepageCarousel").addClass("fade-in-slideshow");
  	// $('.carousel-control').addClass('slide-in');
   	$('#navbar-home').addClass('fade-in-nav');
   	$('img.down-arrow').addClass('fade-in-arrow');
});

// fade in nav items one at a time

// $("ul.left-menu li.dropdown").hover(function() {
// 	$('.dropdown-menu ul > li ').each(function(i) {
// 	$(this).delay((i++) * 250).fadeTo(100, 1); })

// })



}]);
