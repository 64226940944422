'use strict';
/* global $, angular */

angular.module('app.communityList')
.directive('mobileScreenHeight', [function () {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {

          var screenHeight = $(window).height(),
          		navHeight = $(".mobile-wrap").height(),
          		mobileHeader = $(".mobile-header").height(),
          		footerHeight = $("footer").height(),
          		subtraction = navHeight + mobileHeader + footerHeight + 20,
          		mapHeight = screenHeight - subtraction;

            if($(window).width() < 768) {
            	$(attr.parentObject).css('height', screenHeight - footerHeight); 
            	$(attr.targetObject).css('height', mapHeight); 

              // adding fixed class to mobile nav to fix menu
              $(".mobile-wrap").addClass("fixed")
        	}

      
    }
  };
}]);