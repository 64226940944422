'use strict';
/* global $, angular */

angular.module('app.goGreen')
.directive('carouselSlide', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {

      $(elem).bind('slid.bs.carousel', function (e) {
          $(attr.targetElement).addClass(attr.targetClass);

          if(attr.stagger !== undefined && $(".item.no-zoom").hasClass("active"))
          {
            setTimeout(function(){
              //elem.find(attr.stagger).velocity("transition."+attr.transition,{stagger: attr.speed});
              $(".item.active").find(attr.stagger).velocity("transition."+attr.transition,{stagger: attr.speed});
            },attr.delay)
          } 

          $timeout(function(){
            $(".next-step").fadeIn(500);
          }, 1200)

          $(attr.addFade).addClass("fade");

          // add's active class to menu. not a great solution but unable to attach click handler to menu because of ng-non-bindable attribute.
          if($(".slide-0").hasClass("active")) {
                $("a.menu").removeClass("active");
                $("a.menu-1").addClass("active");
          }

          if($(".slide-1").hasClass("active")) {
                $("a.menu").removeClass("active");
                $("a.menu-2").addClass("active");
          }

          if($(".slide-2").hasClass("active")) {
                $("a.menu").removeClass("active");
                $("a.menu-3").addClass("active");
          }

          if($(".slide-3").hasClass("active")) {
                $("a.menu").removeClass("active");
                $("a.menu-4").addClass("active");
          }

          if($(".slide-4").hasClass("active")) {
                $("a.menu").removeClass("active");
                $("a.menu-5").addClass("active");
          }

      
      });

    //link to slide 2 from homepage CTA

    if (location.hash === "#entered") {
        $(function() {
            $('#goGreenCarousel').carousel(1);
        });
    }


      }

  };
}]);