(function() {

  'use strict';
  /* global angular */

  angular
    .module("modules.googleMaps")

    .service('LocalAmenitiesService', ['$sce',
      function($sce) {

        return {
          data: defaultLocalAmenities
        };

      }
    ])
  ;

  var defaultLocalAmenities = [
    {
      name: 'Schools',
      types: [
        'school'
      ]
    },
    {
      name: 'Restaurants',
      types: [
        'bakery',
        'bar',
        'cafe',
        'food',
        'meal_delivery',
        'meal_takeaway',
        'restaurant'
      ]
    },
    {
      name: 'Grocery',
      types: [
        'grocery_or_supermarket'
      ]
    },
    {
      name: 'Retail',
      types: [
        'beauty_salon',
        'bicycle_store',
        'book_store',
        'clothing_store',
        'department_store',
        'electronics_store',
        'furniture_store',
        'hair_care',
        'hardware_store',
        'home_goods_store',
        'jewelry_store',
        'pet_store',
        'shoe_store',
        'shopping_mall'
      ]
    },
    {
      name: 'Entertainment',
      types: [
        'amusement_park',
        'bowling_alley',
        'casino',
        'movie_rental',
        'movie_theater',
        'night_club',
        'spa',
        'stadium'
      ]
    },
    {
      name: 'Recreation',
      types: [
        'aquarium',
        'art_gallery',
        'campground',
        'museum',
        'park',
        'zoo'
      ]
    },
    {
      name: 'Hospitals',
      types: [
        'hospital'
      ]
    }
  ];

})();