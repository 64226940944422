(function AppHomepageServicesWhereWeBuildMapOriginCoordinatesService() {
  "use strict";

  angular

    .module('app.homepage.services')

    .service('whereWeBuildMapOriginCoordinatesService', function() {

      return {
        // San Rafael 
        topLeft: {
          lat: 37.980187,
          lng: -122.548208
        },
        // Coyote, CA 
        bottomRight: {
          lat: 37.204721,
          lng: -121.716315
        }
      };
    })
  ;

})();