(function AppHomepageControllersCommunitiesTiles() {
  "use strict";

  angular

    .module('app.homepage.controllers')

    .controller('whereWeBuildTilesCtrl', ['$scope', 'CommunitiesDataService',
      function($scope, CommunitiesDataService) {

        $scope.communities = CommunitiesDataService.communities;

      }
    ])

  ;
})();
