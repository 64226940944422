(function() {
  "use strict";

  angular
    .module('app.aboutProfile')
    .controller('AboutProfileCtrl', ['$scope', 'device',
      function($scope, device) {

        // The function actually applying the offset
        function offsetAnchor() {
          if (location.hash.length !== 0) {
            if (device.check() == 'phone' || device.check() == 'ipad') {
              var locationHash = location.hash;
              if (locationHash == '#/awards' || locationHash == '#/news-and-press') {
                var tabId = locationHash.replace('/', '');
                $('.nav-tabs a[href=' + tabId + ']').tab('show') ;
                window.scrollTo(window.scrollX, $('#scroll-section-1').offset().top - 150);
              } else if (locationHash == '#/testimonials') {
                window.scrollTo(window.scrollX, $('#testimonials').offset().top - 150);
              }
            } else {
              window.scrollTo(window.scrollX, window.scrollY - 220);
            }
          }
        }

        // scroll to news and press tab upon page load
        $(window).on("load", function() {
          window.scrollTo(window.scrollX, $('#scroll-section-1').offset().top - 150);
          var panel = $('.panel-collapse.in, .card-content.in'),
          panelHeight = $(panel).height();
          $("#awards").css('height', panelHeight);  
        });

        // This will capture hash changes while on the page
        $(window).on("hashchange", function() {
          // Javascript to enable link to tab
          var url = document.location.toString();
          if (url.match('#')) {
            $('.nav-tabs a[href=#'+url.split('#/')[1]+']').tab('show') ;
          }

          offsetAnchor();

        // // Change hash for page-reload
        // $('.nav-tabs a').on('shown.bs.tab', function (e) {
        //     window.location.hash = e.target.hash;
        // })
        });

        // Javascript to enable link to tab
        // var url = document.location.toString();
        // if (url.match('#')) {
        //   $('.nav-tabs a[href=#' + url.split('#')[1] + ']').tab('show') ;
        // }

        // Change hash for page-reload
        // $('.nav-tabs a').on('shown.bs.tab', function(e) {
        //   window.location.hash = e.target.hash;
        // });

        // This is here so that when you enter the page with a hash,
        // it can provide the offset in that case too. Having a timeout
        // seems necessary to allow the browser to jump to the anchor first.
        // window.setTimeout(function() {
        //   offsetAnchor();
        // }, 1);

        // open first awards panel on page laod
        $("#accordion a:first").trigger("click");

        // prevent open collapse from closing on click
        if ($(window).width() > 992) {
          console.log('not phone or tablet');
          $('.panel-heading a').on('click',function(e){
            if($(this).parents('.panel').children('.panel-collapse').hasClass('in')){
                e.stopPropagation();
            }
          });
        }

      }
    ])
  ;
})();
