
  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('shStyledCommunityDropdown', function() {
      return {
        restrict: 'E',
        scope: {
          defaultPlaceholder: '@',
          selectedCommunity: '=ngModel'
        },
        templateUrl: 'sh-styled-community-dropdown.html',
        controller: [ "$scope", "CommunitiesDataService", function($scope, CommunitiesDataService) {
          $scope.selectedCommunity = '';

          if(! $scope.defaultPlaceholder) {
              $scope.defaultPlaceholder = 'Please specify your community*';
          }

          $scope.selectCommunity = function selectCommunity(community) {
            $scope.defaultPlaceholder = community.name;
            $scope.selectedCommunity = community.id;
          };

          $scope.communities = CommunitiesDataService.communities;

        }]
      };
    });
