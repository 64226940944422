(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    /* global jsonCache */
    .service("CommunityCachedDataService", [
      function() {

        var data = {
          community: false
        };

        if (typeof jsonCache === "undefined") {
          return data;
        }

        if (typeof jsonCache.community === "undefined") {
          return data;
        }

        data.community = jsonCache.community;

        return data;
      }
    ]);

})();