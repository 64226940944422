(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.microsite')
    .controller('MicrositeFloorplanGalleryCtrl', ['$scope', 'MicrositeGalleryAssets',
      function($scope, MicrositeGalleryAssets) {

        $scope.selectedIndex = 0;

        $scope.residence = MicrositeGalleryAssets.residence;
        $scope.gallery = MicrositeGalleryAssets.assets;

        // initialize the carousel
        $('#gallery-carousel').carousel();

        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.swipeLeft = function() {
          console.log("swiped next");
          $('.carousel').carousel('next');
        };

        $scope.swipeRight = function() {
          console.log("swiped prev");
          $('.carousel').carousel('prev');
        };

      }
    ])
  ;

})();