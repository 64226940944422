(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.microsite')
    .service('MicrositeGalleryAssets', [
      function() {
        
        var data = {
          residence: {},
          assets: []
        };

        return data;
      }
    ])
  ;

})();
