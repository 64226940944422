angular.module('app.locallyOwned')
.controller('LocallyOwnedCtrl',['$scope', function ($scope) {

	$scope.hero = function(inview, inviewpart, event) {
		if (inview) {
		$('.locally-owned-hero').addClass('fade-in');
		}
	};

	$scope.whyChooseUs = function(inview, inviewpart, event) {
		if (inview) {
			$('.why-choose-us').addClass('fade-in');
		}
	};

}]);
