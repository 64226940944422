(function AppHomepageControllersCommunitiesMap() {
  "use strict";

  angular

    .module('app.homepage.controllers')

    .controller('whereWeBuildMapCtrl', ['$scope', 'CommunitiesDataService',
      function($scope, CommunitiesDataService) {

        $scope.staticMapPins = [];

        CommunitiesDataService
          .promise
          .then(function(communities) {

            angular.forEach(communities, function(community) {

              //this should really be done with a directive
              $scope.staticMapPins.push({
                community: community,
                position: {
                  left: community.static_map_x,
                  top: community.static_map_y
                }
              });

            });

          });

      }
    ])

  ;
})();
