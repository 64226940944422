(function () {
    'use strict';
    const ScheduleTourController = (function() {
        const scheduleTourController = function($scope, $rootScope, $http, $window, dummyDataService) {
            this.$scope = $scope;
            this.$rootScope = $rootScope;
            this.$http = $http;
            this.showLoader = false;
            this.hideForm = false;
            this.triedSubmit = false;
            this.message = '';
            this.showMessage = false;
            this.isDatePickerOpen = false;
            this.initialDate = '';
            this.dateOptions = {

            };
            this.fields = {
                form: 'ScheduleTourForm',
                type: 'scheduleTour',
                origin: $window.location.href,
                communities: '',
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                hear: '',
                hear_other: '',
                tour_date: new Date(),
                tour_time: '',
                meeting: '',
                guid: ''
            };

            this.checkFormValidation = this.checkFormValidation.bind(this);
            this.processScheduleTourForm = this.processScheduleTourForm.bind(this);
            this.getCommunities = this.getCommunities.bind(this);
            this.handleCommunityChange = this.handleCommunityChange.bind(this);
            this.handleMeetingChange = this.handleMeetingChange.bind(this);
            this.getCommunities = this.getCommunities.bind(this);

            if (typeof window.LassoCRM !== 'undefined' && window.LassoCRM) {
                this.fields.guid = LassoCRM.tracker.readCookie('ut');
            }

            this.communityOpts = [];
            this.getCommunities();
            if (!this.selectedCommunity) {
                this.$scope.$watch('selectedCommunity', function(newValue, oldValue, scope) {
                    scope.vm.fields.communities = scope.vm.communityOpts.filter(function(community) {
                        return community.id == newValue;
                    })[0];

                });
            }

            this._defaultHears = dummyDataService.newHears();
            this._defaultContacts = dummyDataService.contacts();
            this._defaultTourTimes = dummyDataService.tourTimes();

            // Shallow copies of the default values
            this.hears = this._defaultHears.map(function(hear) { return hear; });
            this.contacts = this._defaultContacts.map(function(contact) { return contact; });
            this.tourTimes = this._defaultTourTimes.map(function(tourTime) { return tourTime; });
        }

        /**
         * getCommunities
         * Maps the `this.communities` object to an array of objects with
         * `id` and `name` properties
         */
        scheduleTourController.prototype.getCommunities = function() {
            // `this.communities` is bound to controller from directive
            // in schedule-tour-form.blade.php
            const communities = this.communities;
            const communityKeys = Object.keys(communities);
            const selectedCommunity = this.selectedCommunity;
            const _this = this;

            this.communityOpts = communityKeys.map(function(key) {
                return {id: key, name: communities[key]};
            });
            if (selectedCommunity && !_this.nonTourCommunities.includes(selectedCommunity)) {
                this.fields.communities = this.communityOpts.filter(function(community) {
                    return community.id == selectedCommunity;
                })[0];
            } else if (selectedCommunity && _this.nonTourCommunities.includes(selectedCommunity)) {
                this.communityOpts = communityKeys.map(function(key) {
                    return {id: key, name: communities[key]};
                }).filter((function(community) {
                    return !_this.nonTourCommunities.includes(community.id);
                }));
            }

        };

        scheduleTourController.prototype.handleCommunityChange = function() {

            console.log('handling community change');
            const contactMap = function (contact) {
                return contact;
            };
            const contactFilter = function (contact) {
                return contact.key != 'schedule-a-tour';
            }
            const nonTourCommunities = this.nonTourCommunities;
            let communityId = this.fields.communities && (typeof this.fields.communities === 'string' ? this.fields.communities : this.fields.communities.id);
            if (communityId) {
                communityId = parseInt(communityId, 10);
            }
            if (nonTourCommunities.includes(communityId) && this.fields.meeting == 'schedule-a-tour') {
                this.fields.meeting = '';
            }
            console.log('community', nonTourCommunities, communityId, this.fields.meeting);
            if (nonTourCommunities.includes(communityId)) {
                this.fields.meeting = '';
                this.contacts = this._defaultContacts.filter(contactFilter.bind(this));
            } else {
                this.contacts = this._defaultContacts.map(contactMap.bind(this));
            }

        };


        scheduleTourController.prototype.handleMeetingChange = function() {
            console.log('handling meeting change', this.nonTourCommunities);
            const communities = this.communities;
            const communityKeys = Object.keys(communities);
            const nonTourCommunities = this.nonTourCommunities;
            let currentCommunityId = this.fields.communities && (typeof this.fields.communities === 'string' ? this.fields.communities : this.fields.communities.id);
            if (currentCommunityId) {
                currentCommunityId = parseInt(currentCommunityId, 10);
            }

            if (this.fields.meeting == 'schedule-a-tour' && currentCommunityId && nonTourCommunities.includes(currentCommunityId)) {
                this.fields.communities = '';
            }

            if (this.fields.meeting == 'schedule-a-tour') {
                this.communityOpts = communityKeys.map(function(key) {
                    return {id: key, name: communities[key]};
                }).filter(function(community) {
                    const communityId = parseInt(community.id, 10);
                    console.log(nonTourCommunities, communityId, !nonTourCommunities.includes(communityId));
                    return !nonTourCommunities.includes(communityId);
                });
                console.log(this.communityOpts);
            } else {
                this.communityOpts = communityKeys.map(function(key) {
                    return {id: key, name: communities[key]};
                });
            }
        };

        scheduleTourController.prototype.checkFormValidation = function(form) {

            if (!this.fields.tour_date && form.tour_date) {
                this.initialDate = form.tour_date.$viewValue;
                this.fields.tour_date = new Date(form.tour_date.$viewValue);
            }

            this.triedSubmit = true;


            if (form.$valid || (!form.$valid && form.$error.date))  {
                this.processScheduleTourForm();
            }
        };

        scheduleTourController.prototype.processScheduleTourForm = function() {
            const vm = this;
            const dataToSend = Object.assign({}, vm.fields);
            this.hideForm = true;
            this.showLoader = true;

            if (this.initialDate) {
                dataToSend.tour_date = new Date(this.initialDate).toISOString();
            } else {
                dataToSend.tour_date = '';
            }

            this.$http({
                method: 'POST',
                url: '/form/to-database',
                data: $.param(dataToSend),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).success(function(data) {
                vm.showLoader = false;
                if (data.status === 'success') {
                    // Activate this when we have the details
                    vm.sendSuccessSeo(data);

                    // Add script tag for mathtag
                    const scriptElem = document.createElement('script');
                    scriptElem.src = 'https://pixel.mathtag.com/event/js?mt_id=1574576&mt_adid=250760&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=';
                    document.body.appendChild(scriptElem);
                }
                vm.message = data.message;
                vm.showMessage = true;
            });
        };

        scheduleTourController.prototype.sendSuccessSeo = function(data) {
            if (typeof clicky_custom === 'undefined' || typeof clicky === 'undefined') {
                console.log('clicky is undefined. Tracking not firing.');
                return;
            }

            // Load the pseudo confirmation page.
            // create a hidden iframe element and set its source
            var iframe = document.createElement('iframe');

            iframe.style.display = 'none';
            iframe.src = this.pseudoConfirmation || '/forms/pseudo-confirmation';
            iframe.referrerPolicy = 'same-origin';

            document.body.appendChild(iframe);

            clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '0'
            };

            clicky_custom.goal = {
                name: 'Schedule Tour'
            };

            // Log action in clicky
            // @TODO - I don't know what the goal should be for this
            clicky.goal('113'); // Logs a "quick-signup" goal hit in clicky
            clicky.log('/schedule-tour/thank-you'); // Logs a fake page hit in clicky

            // Fire off a Google Analytics goal hit
            ga('create', 'UA-846906-1');
            ga(function (tracker) {
                ga('send', 'pageview', '/ajax-goals/schedule-tour');
            });
        };

        return scheduleTourController;
    })();

    ScheduleTourController.$inject = ['$scope', '$rootScope', '$http', '$window', 'dummyDataService'];

    angular
        .module('app.scheduleTour')
        .directive('scheduleTour', function() {
            return {
                restrict: 'A',
                bindToController: {
                    communities: '=',
                    selectedCommunity: '=?',
                    nonTourCommunities: '=?',
                    pseudoConfirmation: '=?'
                },
                controller: ScheduleTourController,
                controllerAs: 'vm'
            };
        });
})();
