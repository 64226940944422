(function() {

  'use strict';
  /* global $, angular */

  angular
    .module('app.homepage.controllers')
    .controller('GalleryCtrl', ['$scope', 'AssetsDataService',
      function($scope, AssetsDataService) {

        $scope.gallery = [];

        $scope.selectedIndex = 0;

        AssetsDataService
          .promise
          .then(function(assets) {

            angular.forEach(assets, function(asset) {

              // hacky way to get these in a sort of random order
              if (0.5 - Math.random() > 0) {
                $scope.gallery.push(asset);
              } else {
                $scope.gallery.unshift(asset);
              }

            });

            // Just get the first 6 elements from the array
            $scope.gallery = $scope.gallery.slice(0, 6);

          });

        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.swipeLeft = function() {
          console.log("swiped next");
          $('.carousel').carousel('next');
        };

        $scope.swipeRight = function() {
          console.log("swiped prev");
          $('.carousel').carousel('prev');
        };

      }
    ])
  ;

})();