(function() {
  "use strict";

  angular
    .module('app.microsite') // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
    .controller('micrositeSiteplanCtrl', ['$scope', "$location", "CommunityDataService", "laravelParameters", "micrositeSubNavReveal",
      function($scope, $location, CommunityDataService, laravelParameters, micrositeSubNavReveal) {
        
        $scope.seoName = laravelParameters.segment(0);

        $scope.clickPdfLink = function() {
          console.log("Clicked the Site Plan PDF");
        };

        $('#modal-site-plan').on('show.bs.modal', function (e) {
          clicky_custom.goal = {
            name: 'Viewed Lot Map'
          };

          // Log action in clicky
          clicky.goal('1989');      // Logs a "quick-signup" goal hit in clicky

          // Fire off a Google Analytics goal hit
          ga('create', 'UA-846906-1');
          ga(function(tracker) {
            ga('send', 'pageview', '/ajax-goals/lot-map-view');
          });
        });
      }
    ])
  ;

})();
