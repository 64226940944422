
  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('shStyledYourAgeDropdown', function() {
      return {
        restrict: 'E',
        scope: {
          defaultPlaceholder: '@',
          selectedYourAge: '=ngModel',
        },
        templateUrl: 'sh-styled-your-age-dropdown.html',
        controller: [ "$scope", function($scope) {
          $scope.selectedYourAge = '';

          if(! $scope.defaultPlaceholder) {
              $scope.defaultPlaceholder = 'Your Age';
          }

          $scope.selectYourAge = function selectYourAge(yourAge) {
            $scope.defaultPlaceholder = yourAge.name;
            $scope.selectedYourAge = yourAge.value;
          };

          $scope.yourAges = [
              {
                  "value": "18-29",
                  "name": "18 - 29"
              },
              {
                  "value": "30-39",
                  "name": "30 - 39"
              },
              {
                  "value": "40-49",
                  "name": "40 - 49"
              },
              {
                  "value": "50-59",
                  "name": "50 - 59"
              },
              {
                  "value": "60+",
                  "name": "60 +"
              }
          ];

        }]
      };
    });
