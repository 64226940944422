'use strict';
/* global $, angular */

angular.module("app.common").service('chunkData', [function () {
	this.split = function(arr,size){
		var split = Math.round(arr.length/size);
		var newArr = [];
		for (var i=0; i<arr.length; i+=split) {
			newArr.push(arr.slice(i, i+split));
		}
		return newArr;
	}
}]);