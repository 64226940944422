(function() {
  "use strict";

  angular
    .module('app.corporateWebsite')
    .controller('CorporateWebsiteCtrl', ['$scope',
      function($scope) {
        $('.development .nav>li>a').on('click', function(e){
          var text = $(this).text();
          $('.active-selection').text(text);
          $('.navbar-collapse').collapse('hide');
      }); 
      }
    ])
  ;
})();
