(function(){
  "use strict";

  angular
    .module("app.residenceCard", [
      "app.residenceCard.directives"
    ]);

  angular
    .module("app.residenceCard.directives", []);
    
})();