angular.module('app.contact')
.controller('ContactCtrl', ['$scope', function ($scope) {

  // The function actually applying the offset
  function offsetAnchor() {
    if(location.hash.length !== 0) {
        window.scrollTo(window.scrollX, window.scrollY - 130);
    }
  }

  // This will capture hash changes while on the page
  $(window).on("hashchange", function () {
    offsetAnchor();
  });

  // This is here so that when you enter the page with a hash,
  // it can provide the offset in that case too. Having a timeout
  // seems necessary to allow the browser to jump to the anchor first.
  window.setTimeout(function() {
    offsetAnchor();
  }, 1);

  // scroll to hash on page load
  $(window).on("load", function() {
  var locationHash = location.hash;
  console.log(locationHash);
  if(locationHash == '#/community-contacts') {
    window.scrollTo(window.scrollX, $('#community-contacts').offset().top - 175);
  } else if (locationHash == '#/scene') {
    window.scrollTo(window.scrollX, $('#scene').offset().top - 175);
  } else {
    window.scrollTo(window.scrollX, $('#scroll-section-1').offset().top - 120);
  }
  });

}]);