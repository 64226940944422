'use strict';
/* global $, angular */

angular.module("app.common").directive('slideshow', function() {
	return function(scope, element, attrs) {
		$(window).ready(resizeSlideshow);
		$(window).resize(resizeSlideshow);

		function resizeSlideshow(attrs){
			if($("body").hasClass("microsite")) {
				//element.height($(window).height() - 32);
				if( $(window).width() > 767 ) {
					console.log($(window).height());
				$(".sub-nav").attr("data-offset-top", $(window).height() - 50 ); 
				if(element.hasClass('carousel')) {
          element.height($(window).height() - 32);
				}
				} else {
					$(".sub-nav").attr("data-offset-top", $(window).height() );
					// element.height($(window).height() - 261); //235
				}
				$('.carousel').carousel({
		             interval: 6000,
		             pause: "false"
		         })
				//element.height($(window).height() - 32);
			}
			else if($("body").hasClass("buying-your-home")){
				element.height($(window).height() - 90);
			}

			 else {
				element.height($(window).height() - 20); 
			}
		}
	};
});