(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    /* global jsonCache */
    .service("ExecutiveTeamCachedDataService", [
      function() {

        var data = {
          executive_team: false
        };

        if (typeof jsonCache === "undefined") {
          return data;
        }

        if (typeof jsonCache.executive_team === "undefined") {
          return data;
        }

        data.executive_team = jsonCache.executive_team;

        return data;
      }
    ]);

})();