
  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('shStyledHearDropdown', function() {
      return {
        restrict: 'E',
        scope: {
          defaultPlaceholder: '@',
          selectedHear: '=ngModel',
        },
        templateUrl: 'sh-styled-hear-dropdown.html',
        controller: [ "$scope", function($scope) {
          $scope.selectedHear = '';

          if(! $scope.defaultPlaceholder) {
              $scope.defaultPlaceholder = 'How did you hear about us?';
          }

          $scope.selectHear = function selectHear(hear) {
            $scope.defaultPlaceholder = hear.name;
            $scope.selectedHear = hear.value;
          };

          $scope.hears = [
              {
                  "value": "Bing",
                  "name": "Bing"
              },
              {
                  "value": "Craigslist.org",
                  "name": "Craigslist.org"
              },
              {
                  "value": "Twitter",
                  "name": "Twitter"
              },
              {
                  "value": "Google",
                  "name": "Google"
              },
              {
                  "value": "MLSlistings.com",
                  "name": "MLSlistings.com"
              },
              {
                  "value": "Facebook",
                  "name": "Facebook"
              },
              {
                  "value": "Yahoo",
                  "name": "Yahoo"
              },
              {
                  "value": "Trulia",
                  "name": "Trulia"
              },
              {
                  "value": "Zillow",
                  "name": "Zillow"
              },
              {
                  "value": "Redfin",
                  "name": "Redfin"
              },
              {
                  "value": "Other Website",
                  "name": "Other Website"
              },
              {
                  "value": "Direct Mail",
                  "name": "Direct Mail"
              },
              {
                  "value": "Realtor Referral",
                  "name": "Broker / Realtor"
              },
              {
                  "value": "Friend/Relative",
                  "name": "Friend / Relative"
              },
              {
                  "value": "Newspaper/Mapguide",
                  "name": "Newspaper / Mapguide"
              },
              {
                  "value": "Already own a SummerHill home",
                  "name": "Already Own a SummerHill Home"
              },
              {
                  "value": "Other",
                  "name": "Other"
              }
          ];

        }]
      };
    });
