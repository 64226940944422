(function() {
  "use strict";

  angular
    .module('app.footer', ["modules.dataServices"])
    .controller('FooterCtrl', ['$scope', 'CommunitiesDataService',
      function($scope, CommunitiesDataService) {


        $scope.communities = [];

        CommunitiesDataService
          .promise
          .then(function(communities) {
            angular.copy(communities, $scope.communities);
          })
        ;


      }
    ])
  ;

})();