angular.module('app.portfolio')
.controller('PortfolioCtrl',['$rootScope', '$scope', 'GalleriesDataService',
  function ($rootScope, $scope, GalleriesDataService) {

    $scope.selectedIndex = 0;

    GalleriesDataService
      .promise
      .then(function(galleries) {
        console.log(galleries);
        $scope.galleries = galleries;
        $scope.cities = _.uniq(_.pluck(_.sortBy(galleries, 'city'), 'city'));
      })
    ;

    $scope.filterGalleries = function(city) {
      $scope.selectedCity = city;
    }

    $scope.readMore = function(gallery) {
      $rootScope.galleryModal = gallery;
    }

    $scope.viewImages = function(gallery) {
      $scope.residence = {
        name: gallery.name
      };
      $scope.gallery = gallery.assets;

      $('#modal-gallery').modal('show');
    };

    $scope.residence = {};
    $scope.playerVars = {
      autoplay: 1
    };

    var videoFunc ='';
    var outerDiv = document.getElementById('modal-video');

    $scope.viewVideo = function(gallery) {
      angular.copy(gallery, $scope.residence);

      $('#modal-video').modal('show');
    };

    $('#modal-video').on('hide.bs.modal', function (e) {
      var youtubeIframe = outerDiv.getElementsByTagName('iframe')[0].contentWindow;
      videoFunc = 'pauseVideo';
      youtubeIframe.postMessage('{"event":"command","func":"' + videoFunc + '","args":""}', '*');
    });

}]);