(function() {
  'use strict';
  /* global $, angular */

  angular
    .module('app.microsite')
    .directive('navItem', [ '$location', 'micrositeSubNavReveal', 'device',
      function($location, micrositeSubNavReveal, device) {
        return {
          restrict: 'A',
          scope: {
            'href': '@navHref',
            'click': '=navClick',
          },
          transclude: true,
          template: "<a ng-class=\"{ active: activeTab(href) }\" ng-href=\"<% href %>\" ng-click=\"clickCallback($event)\" ng-transclude>",
          link: function(scope, elem, attr) { 

            

            scope.clickCallback = function(event) {
              if (scope.click) {
                event.preventDefault();
                scope.click();
              }
            };

            scope.reveal = function(tab) {
              var result = micrositeSubNavReveal[tab];

              if (result) {
                //elem.removeClass('ng-hide');
              } else {
                //elem.addClass('ng-hide');
              }

              return result;
            };

            scope.activeTab = function(tab) {
              var active = ('/' + tab === $location.path());

              // This is a hack so the parent <li> will get the active class.
              // I think we should apply the active class only to the link, but... -wo 
              if (active) {
                elem.addClass('active');
              } else {
                elem.removeClass('active');
              }

              return active;
            };

          }
        };
      }
    ])
  ;
})();