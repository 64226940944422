angular.module('app.buyingYourHome')
.controller('BuyingYourHomeCtrl', ['$scope', function ($scope) {

  // The function actually applying the offset
function offsetAnchor() {
    if(location.hash.length !== 0) {
        window.scrollTo(window.scrollX, window.scrollY - 130);
    }
}

// This will capture hash changes while on the page
$(window).on("hashchange", function () {
    offsetAnchor();
});

// This is here so that when you enter the page with a hash,
// it can provide the offset in that case too. Having a timeout
// seems necessary to allow the browser to jump to the anchor first.
window.setTimeout(function() {
    offsetAnchor();
}, 1);

// scroll to hash on page load
$(window).on("load", function() {
  var locationHash = location.hash;
  console.log(locationHash);
  if(locationHash == '#/preferred-lenders') {
    window.scrollTo(window.scrollX, $('#preferred-lenders').offset().top - 270);
  } else if (locationHash == '#/mortgage-calculator') {
    window.scrollTo(window.scrollX, $('#mortgage-calculator').offset().top - 20);
  } else {
    window.scrollTo(window.scrollX, $('#scroll-section-1').offset().top - 120);
  }
});

$scope.watchTourDesignGallery = function() {
  $('#modal-video').modal('show');
};

  $scope.selectedIndex = 0;
  $scope.gallery = [{
    id: 1,
    image: { hd: '/images/buying-your-home/design-center-01.jpg' }
  }, {
    id: 2,
    image: { hd: '/images/buying-your-home/design-center-02.jpg' }
  }, {
    id: 3,
    image: { hd: '/images/buying-your-home/design-center-03.jpg' }
  }, {
    id: 4,
    image: { hd: '/images/buying-your-home/design-center-04.jpg' }
  }, {
    id: 5,
    image: { hd: '/images/buying-your-home/design-center-05.jpg' }
  }, {
    id: 6,
    image: { hd: '/images/buying-your-home/design-center-06.jpg' }
  }, {
    id: 7,
    image: { hd: '/images/buying-your-home/design-center-07.jpg' }
  }, {
    id: 8,
    image: { hd: '/images/buying-your-home/design-center-08.jpg' }
  }];

  $scope.viewPhotoGallery = function() {
    $('#modal-gallery').modal('show');
  };
}]);
