(function() {
  "use strict";

  angular

    .module('app.homepage.controllers')

    .controller('testimonialCtrl', ['$scope', 'TestimonialsDataService',
      function($scope, TestimonialsDataService) {

        $scope.testimonials = [];

        TestimonialsDataService
          .promise
          .then(function(testimonials) {

            testimonials.randomize();
            testimonials = testimonials.slice(0,6);
            $scope.testimonials = testimonials;

          });

        Array.prototype.randomize = function() {
          var i = this.length, j, temp;
          while ( --i ) {
            j = Math.floor( Math.random() * (i - 1) );
            temp = this[i];
            this[i] = this[j];
            this[j] = temp;
          }
        };
        
      }
    ])

  ;
})();
