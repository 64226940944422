(function() {

  'use strict';

  angular
    .module('app')

    .controller("CommunityMapCtrl", ['$scope', '$q', '$timeout', 'uiGmapPromises', 'LocalAmenitiesService', 'googleMapsStylesService', 'CommunitiesDataService',
      function($scope, $q, $timeout, uiGmapPromises, LocalAmenitiesService, googleMapsStylesService, CommunitiesDataService) {

        var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {

          GoogleMaps.event.trigger(theMap, 'resize');

          var newBounds = new GoogleMaps.LatLngBounds();

          angular.forEach($scope.map.markers, function(marker) {
            newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
          });

          //newBounds.extend(new GoogleMaps.LatLng(37.624416, -123.548175));

          theMap.fitBounds(newBounds);
        };

        $scope.closeModal = function () {
          $scope.isInfoWindowOpen = false;
        };

        $scope.map = {
          center: {
            latitude: 37.6275547,
            longitude: -122.2505922
          },
          control: {},
          markers: [],
          zoom: 10,
          options: {
            styles: googleMapsStylesService.amenitiesStyle
          }
        };

        CommunitiesDataService
          .promise
          .then(function(communities) {
            $scope.communities = communities;
          })
          .then(uiGmapPromises.GoogleMapsApiReady)
          .then(function init(GoogleMaps) {

            $scope.icon = {
              url: '/images/microsite/blue-icon-pin.png',
              scaledSize: new GoogleMaps.Size(98/2, 120/2)
            };

            angular.copy({
              center: {
                latitude: 37.6275547,
                longitude: -122.2505922
              },
              control: {},
              markers: [],
              zoom: 10,
              options: {
                streetViewControl: false,
                draggable: true,
                scrollwheel: true,
                mapTypeControl: false,
                panControl: true,
                panControlOptions: {
                  position: GoogleMaps.ControlPosition.RIGHT_CENTER
                },
                zoomControl: true,
                zoomControlOptions: {
                  position: GoogleMaps.ControlPosition.RIGHT_CENTER
                },
                styles: googleMapsStylesService.amenitiesStyle
              },
              markersEvents: {
                click: function(marker, eventName, model, args) {
                 // $scope.map.window.control.hideWindow();
                  //$scope.map.window.coords = marker.center;
                  //$scope.map.window.show = false;
                  //$scope.isInfoWindowOpen = false;
                  $scope.isInfoWindowOpen = true;
                  //console.log($scope.communities);
                  this.model = {};

                  $timeout(function(){
                   // $scope.map.window.marker = marker;
                   $scope.parameter = model;
                    console.log(model);
                   // $scope.map.window.show = true;
                    //$scope.isInfoWindowOpen = true; 
                  });

                }
              },
              window: {
                control: {},
                marker: {},
                show: false,
                closeClick: function() {
                  this.show = false;
                  this.model = {};
                },
                options: {
                  maxWidth: 800
                }
              }
            }, $scope.map);


          })
          .then(uiGmapPromises.GoogleMapsIsAllReady)
          .then(function addMarkers() {
            angular.forEach($scope.communities, function(community) {

              var marker = {
                place_id: community.id,
                name: community.name,
                latitude: community.latitude,
                longitude: community.longitude,
                community: community,
                icon: $scope.icon,               
                options: {
                  visible: true
                }
              };

              $scope.map.markers.push(marker);

            });
          })
          .then(function() {

            $timeout(function() {

              // re-size and re-center map
              uiGmapPromises.GoogleMapsApiReady()
                .then(function init(GoogleMaps) {
                  uiGmapPromises.GoogleMapsIsAllReady()
                    .then(function(theMap) {

                      $scope.map.window.options.pixelOffset = new GoogleMaps.Size(-6, -53);
                      fitBoundsWithCurrentMarkers(theMap, GoogleMaps);

                      // resize trigger
                      GoogleMaps.event.addDomListener(window, "resize", function() {
                        fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                      });

                    });
                })
              ;

            });
          });
      }
    ])
  ;


})();