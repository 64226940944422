(function() {


  'use strict';
  /* global $, angular */

  angular.module('app')
    .directive('cardAccordian', [
      function() {
        return {
          restrict: 'A',
          link: function(scope, elem) {

            elem.on('click', function() {
              if (!elem.hasClass('active')) {
                elem.closest('.cards-container').find('.active').removeClass('active');
              }
              elem.toggleClass('active');
            });
          }
        };
      }
    ])
  ;

})();
