(function() {

  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('shFormValidation', function() {
      return {
        restrict: 'E',
        scope: {
          triedSubmit: '=',
          formElement: '=',
          noDate: '=',
          noHear: '=',
          noCommunities: '='
        },
        templateUrl: 'sh-form-validation.html',
        controller: [ "$scope", function($scope) {
        }]
      };
    });

})();
