(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("GalleriesDataService", ['$http', '$q', 'GalleriesCachedDataService',
      function($http, $q, GalleriesCachedDataService) {

        var data = {
          galleries: [],
          promise: null
        };

        if (GalleriesCachedDataService.galleries.length > 0) {

          // Wrap the cached data in a promise to provide a consistent api
          data.promise = $q.when(GalleriesCachedDataService.galleries);

        } else {

          data.promise = $http.get('/portfolio', {
            cache: true
          })
            .then(function(result) {
              return result.data.galleries;
            });

        }

        // This lets you use the service in a simple Angular-y way, just
        // wait for the promise to resolve internally, and let $digest
        // discover the data.
        data.promise
          .then(function(galleries) {
            angular.copy(galleries, data.galleries);
          });

        return data;
      }
    ]);

})();