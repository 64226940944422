(function(){
  "use strict";

  angular
    .module("app.communityCard", [
      "app.communityCard.directives"
    ]);

  angular
    .module("app.communityCard.directives", []);
    
})();