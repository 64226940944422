(function () {
  'use strict';

  angular
    .module('app.quickSignUp')
    .controller('QuickSignUpCtrl', ['$scope', '$rootScope', '$window', '$http', '$filter', 'CommunitiesDataService', 'laravelParameters', 'dummyDataService',
      function ($scope, $rootScope, $window, $http, $filter, CommunitiesDataService, laravelParameters, dummyDataService) {
        $scope.states = dummyDataService.newStates();
        $scope.hears = dummyDataService.newHears();
        $scope.reasons = dummyDataService.reasons();
        $scope.communities = [];

        // for community dropdown
        CommunitiesDataService
          .promise
          .then(function(communities) {
            _.each($filter('filter')(communities, { availability:'!presale' }), function (community) {
              $scope.communities.push({ key: community.id, value: community.name, collections: community.collections, subregion_id: community.subregion_id });
              if (typeof communityId !== 'undefined' && communityId && communityId === community.id) {
                $scope.community = community;
              }
            });
          });

        $scope.community = {};
        $scope.features = {};

        $scope.signup = {};
        $scope.signup.form = 'QuickSignupForm';
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'signUp';
        $scope.signup.communities = [];

        if (typeof window.LassoCRM !== 'undefined' && window.LassoCRM) {
          $scope.signup.guid = LassoCRM.tracker.readCookie('ut');
        }

        if (typeof communityId !== 'undefined' && communityId) {
          $scope.signup.communities[0] = communityId;
        }

        $scope.$watch('signup.communities[0]', function(newValue, oldValue) {
          $scope.community = $filter('filter')($scope.communities, { key:newValue })[0];
        });

        $scope.signup.collections = [];

        // toggle collection selections
        $scope.toggleCollectionSelection = function toggleCollectionSelection (id) {
          var idx = $scope.signup.collections.indexOf(id);
          // is currently selected
          if (idx > -1) {
            $scope.signup.collections.splice(idx, 1);
          }
          // is newly selected
          else {
            $scope.signup.collections.push(id);
          }
        };

        $scope.hideForm = false;
        $scope.showMessage = false;
        $scope.showLoader = false;
        $scope.triedSubmit = false;

        $scope.checkFormValidation = function (form) {
          $scope.triedSubmit = true;

          if (!form.$valid) {
            return;
          }

          processQuickSignUpForm();
        };

        function processQuickSignUpForm () {
          $scope.hideForm = true;
          $scope.showLoader = true;
          $http({
            method: 'POST',
            url: '/form/to-database',
            data: $.param($scope.signup), // pass in data as strings
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          })
            .success(function (data) {
              $scope.showLoader = false;
              if (data.status === 'success') {
                // Activate this when we have the details
                sendSuccessSeo(data);

                // Add script tag for mathtag
                const scriptElem = document.createElement('script');
                scriptElem.src = 'https://pixel.mathtag.com/event/js?mt_id=1574576&mt_adid=250760&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=';
                document.body.appendChild(scriptElem);

                $scope.message = data.message;
                $scope.showMessage = true;

                $window.loadPseudoConfirmation('/forms/pseudo-confirmation/get_updates_form');
              } else {
                $scope.message = data.message;
                $scope.showMessage = true;
              }
            });
        }

        var sendSuccessSeo = function (data) {
          if (typeof clicky_custom === 'undefined' || typeof clicky === 'undefined') { 
            console.log('clicky is undefined. Tracking not firing.');
            return;
          }

          // Tie lead id and email to clicky analytics data
          clicky_custom.visitor = {
            lead_id: data.id,
            email: data.email,
            signup: '1'
          };

          clicky_custom.goal = {
            name: 'Quick Signup'
          };

          // Log action in clicky
          clicky.goal('113'); // Logs a "quick-signup" goal hit in clicky
          clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

          // Fire off a Google Analytics goal hit
          ga('create', 'UA-846906-1');
          ga(function (tracker) {
            ga('send', 'pageview', '/ajax-goals/quick-signup');
          });
        };
      }
    ])
  ;
})();
