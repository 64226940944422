'use strict';
/* global $, angular */

angular.module("app.common").directive('setHeight', ['device', function(device) {
	return function(scope, element, attrs) {
            if(device.check() != "phone") {
                element.bind('click',function(){
                    setInterval(function () {
                            var panel = $('.panel-collapse.in, .card-content.in'),
                            panelHeight = $(panel).height();
                            $(attrs.targetObject).css('height', panelHeight); 
                        }, 50);
                })
            }
		
	};
}]);