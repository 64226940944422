'use strict';
/* global $, angular */

angular.module('app.goGreen')
.directive('nextSlide', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {

      scope.nextSlide = function($last){
    //$(".item").addClass("no-zoom");

        $(".item.active").removeClass("no-zoom");
        $(".pin.natural-resources").fadeOut(300);
              $timeout(function(){
                $(".pin.healthier-homes").fadeOut(300);
              }, 200);
              $timeout(function(){
                $(".pin.water-conservancy").fadeOut(300);
               }, 300);
              $timeout(function(){
                $(".pin.energy-efficient").fadeOut(300);
              }, 400);
              $timeout(function(){
                $(".pin.technology").fadeOut(300);
              }, 500);

              $timeout(function(){
                $(".next-step").fadeOut(300);
              }, 500)
              
              $(".caption").addClass("fade-out");
          $timeout(function(){
            if($last) {
              $('.carousel').carousel(0);
              $(".item").addClass("no-zoom");
              $(".caption").removeClass("fade-out");
            }
          $('.carousel').carousel('next'); //This will be delayed for one second
          $(".item").addClass("no-zoom");
          $(".caption").removeClass("fade-out");
        }, 1400);
}
  
    }

  };
}]);