angular
  .module('app.executiveTeam')
  .controller('ExecutiveTeamCtrl', ['$scope', '$modal', 'ExecutiveTeamDataService', function ($scope, $modal, ExecutiveTeamDataService) {

    ExecutiveTeamDataService
      .promise
      .then(function(executive_team) {
        console.log(executive_team);
        $scope.executive_team = executive_team;
      })
    ;

    $scope.executive_team = [
      {
        name: 'Robert Freed',
        title: 'President and Chief Executive Officer',
        thumb: '/images/company-profile/robert-freed-thumb.jpg',
        shortbio: 'Robert Freed draws from a dynamic background in real estate, corporate finance, and accounting to oversee SummerHill Homes’ critical areas of development, including land acquisition, architecture, purchasing, construction, sales and marketing, and customer service. In this capacity, he is charged with maximizing company profitability while ensuring the highest quality of development.',
        longbio: 'From 1993 to 2007, Mr. Freed held a variety of executive positions for KB Home. Most recently, he was the senior vice president of investment strategy. In this capacity, Mr. Freed was responsible for land acquisitions, joint ventures and off balance sheet financing for all of KB Home’s U.S. operations. In addition, Mr. Freed was president and regional general manager of KB Home’s four Northern California homebuilding divisions. In this capacity, Freed was responsible for overseeing day-to-day operations, including land acquisition and development, construction, purchasing, customer service, sales and marketing. He was also a member of the companys operating executive committee. He began his career with KB Home in 1993 at the company’s corporate headquarters where he served as vice president of western land development. Prior to joining KB Home, Freed was the chief financial officer for Davidon Homes, a division president of Bedford Properties, and CFO of Blackhawk Corporation. Freed earned a Bachelor of Science degree in economics from the University of California, Los Angeles, where he graduated summa cum laude. He is a member of the Phi Beta Kappa Honor Society. Freed is a former certified public accountant, and was a commissioner for the Santa Clara County Housing Authority. Active in nonprofit giving, he served on the board of directors of the Bay Area Council, St. Mary’s College, and the regional board of the BIA Bay Area. He is also a member of the Policy Advisory Board for the Fisher Center for Real Estate and Urban Economics and serves on the Bridge Housing Board of Directors.'
      }
    ];

    $scope.open = function(object) {
      $scope.profile = object;

      var modalInstance = $modal.open({
        templateUrl: 'companyProfileBioModal.html',
        controller: 'companyProfileBioModalCtrl',
        windowClass: 'modal-auto-size bio',
        animation: true,
        size: 'lg',
        scope: $scope
      });
    };

}]);