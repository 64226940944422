angular.module('app.homesNowReady')
.controller('HomesNowReadyCtrl', ['$rootScope', '$scope', '$timeout', '$filter', '$modal', '$http',
  function ($rootScope, $scope, $timeout, $filter, $modal, $http) {

$scope.scheduleCommunity = function(id) {
    $scope.selectedCommunity = id + '';
};

$scope.availableHomes = [
    {
      name: "Renaissance at Rubion",
      city: "San Ramon, CA",
      thumbImage: '/images/homes-now-ready/floorplan-card-img-1.jpg',
      beds: 5,
      baths: 4.5,
      squareFeet: 4045,
      price: 1988000,
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true,
      email: 'renaissancesales@shhomes.com',
      phone: 925-855-1699,
      address: '1007 Hawkshead Circle, San Ramon, CA 94583'
    },
    {
      name: "Renaissance at Rubion",
      city: "San Ramon, CA",
      thumbImage: '/images/homes-now-ready/floorplan-card-img-1.jpg',
      beds: 5,
      baths: 4.5,
      squareFeet: 4045,
      price: 1988000,
      ifGallery: true,
      ifVideo: true,
      ifFloorplan: true,
      email: 'renaissancesales@shhomes.com',
      phone: 9258551699,
      address: '1007 Hawkshead Circle, San Ramon, CA 94583'
    }

  ];

  $scope.processedCities = [];

  $scope.processedCommunities = [];

  $scope.homeFilters = {
    city: 'all',
    community: 'all',
  };

  $scope.filteredHomes = [];

  $scope.loadHomes = function() {
    $http.get('/homes-now-ready')
      .success(function(response) {
        if (response.success) {
          $scope.homes = response.homes;

          $scope.$watch('homeFilters', function (filters, oldFilters) {
            $scope.filteredHomes = $filter('homeFilter')($scope.homes, $scope.homeFilters);

            formatHomes($scope.filteredHomes);

            setFilterParams();

  }, true);
        } else {
          console.log('error');
        }
    });
  };

  $scope.loadHomes();

  function formatHomes(homes) {
    $scope.content = [];

    var communityNames = _.uniq(_.pluck(homes, 'community.name'));
    _.each(communityNames, function(communityName, index) {
      $scope.content.push({ name: communityName });
      $scope.content[index].homes = _.where(homes, { community: { name: communityName } });
    });
  }

  function setFilterParams() {
    angular.forEach($scope.filteredHomes, function (home) {
      var tempCity = {
        key: home.city,
        value: home.city
      };
      $scope.processedCities.push(tempCity);

      var tempCommunity = {
        key: home.community.id,
        value: home.community.name
      };
      $scope.processedCommunities.push(tempCommunity);
    });

    $scope.processedCities = _.sortBy(_.uniq($scope.processedCities, 'key'), 'value');
    $scope.processedCommunities = _.sortBy(_.uniq($scope.processedCommunities, 'key'), 'value');
  }

  $scope.showFeaturesModal = function(home) {
    $rootScope.featuresModalHome = home;
  }

  $scope.openCarouselModal = function (home) {
    // home.assets.length > 0
    if (home.assets.length > 0) {
      $scope.residence = home.residence;
      $scope.gallery = home.assets;
    }
    // home.residence.assets.length > 0
    else {
      $scope.residence = home.residence;
      $scope.gallery = home.residence.assets;
    }

    var modalInstance = $modal.open({
      animation: true,
      templateUrl: 'carouselModal.html',
      controller: 'carouselModalCtrl',
      windowClass: 'carousel-modal',
      scope: $scope
    });
  };

  $scope.openFloorplan = function(home) {
    $scope.is_mobile = /Android|webOS|iPhone|ipad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (! $scope.is_mobile)
    {
      if (home.residence.interactive_floorplans)
      {
        window.open(home.residence.interactive_floorplans, '_blank');
      }
      else if (home.residence.hasInteractiveFloorplans)
      {
        $('#modal-interactive-floorplans').find('.modal-body')
          .empty()
          .load('/microsite/interactive-floorplans-modal/' + home.community.id + '/' + home.residence.seo_name,
            function() {
          });
          $('#modal-interactive-floorplans').modal('show');
      }
      else if (home.residence.floorplanPdf)
      {
        window.open(home.residence.floorplanPdf, '_blank');
      }
    }
    else if ($scope.is_mobile && home.residence.floorplanPdf)
    {
      window.open(home.residence.floorplanPdf, '_blank');
    }
  };

  $scope.isMortgageCalcOpen = false;
  $scope.mortgageCalcOptions = {
    price: 0,
    hoa: 0,
    taxes: 0,
  };
  $scope.selectMortgageCalc = function(home) {
    $scope.mortgageCalcOptions.price = home.price;
    $scope.mortgageCalcOptions.hoa = home.community ? home.community.est_homeowner_fees : 0;
    $scope.mortgageCalcOptions.taxes = home.community ? home.community.est_property_taxes : 0;
    $scope.isMortgageCalcOpen = true;
    $timeout(function () {
      $('#modal-mortgage-calculator').modal('show');
    }, 0);
  };

  $('#modal-interactive-floorplans').on('click', '.btn-select-another-plan .dropdown-menu li', function() {
    var data_url = $(this).data('url');

    $('#modal-interactive-floorplans').find('.modal-body').empty()
      .load(data_url,
        function() {
        });
  });

  $('#modal-interactive-floorplans').on('click', '.select-an-elevation ul li a', function() {
    $('#modal-interactive-floorplans .modal-body > .interactive-floorplans').append('<div class="modal-backdrop-custom"></div>');
    $('.modal-custom').show();
    $('a.close').hide();
  });

  $('#modal-interactive-floorplans').on('click', '.btn-close', function() {
    $('.modal-backdrop-custom').remove();
    $('.modal-custom').hide();
    $('a.close').show();
  });

}])
.filter('homeFilter', function () {
  return function (homes, homeFilters) {
    return homes.filter(function (home) {
      var valid = true;

      Object.keys(homeFilters).forEach(function (key) {
        if (homeFilters[key] && homeFilters[key] !== 'all') {
          switch (key) {
            case 'city':
              if (home.city != homeFilters[key]) {
                valid = false;
              }
              break;
            case 'community':
              if (home.community_id != homeFilters[key]) {
                valid = false;
              }
              break;
          }
        }
      });

      return valid;
    });
  };
});
