(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("ExecutiveTeamDataService", ['$http', '$q', 'ExecutiveTeamCachedDataService',
      function($http, $q, ExecutiveTeamCachedDataService) {

        var data = {
          assets: [],
          promise: null
        };

        if (ExecutiveTeamCachedDataService.executive_team.length > 0) {

          // Wrap the cached data in a promise to provide a consistent api
          data.promise = $q.when(ExecutiveTeamCachedDataService.executive_team);

        } else {

          data.promise = $http.get('/company-profile', {
            cache: true
          })
            .then(function(result) {
              console.log(result.data);
              console.log(result.data.executive_team);
              return result.data.executive_team;
            });

        }

        // This lets you use the service in a simple Angular-y way, just
        // wait for the promise to resolve internally, and let $digest
        // discover the data.
        data.promise
          .then(function(executive_team) {
            angular.copy(executive_team, data.executive_team);
          });

        return data;
      }
    ]);

})();