/* globals angular */
'use strict';

/**
 * IE 11 Polyfill for Array.find method
 */
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
            var o = Object(this);
            var len = o.length >>> 0;
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            var thisArg = arguments[1];
            var k = 0;
            while (k < len) {
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                k++;
            }
            return undefined;
        }
    });
}

/**
 * IE 11 Polyfill for Array.map method
 */
if (!Array.prototype.map) {
    Array.prototype.map = function(callback) {
        var T, A, k;
        if (this == null) {
            throw new TypeError('this is null or not defined');
        }
        var O = Object(this);
        var len = O.length >>> 0;
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }
        if (arguments.length > 1) {
            T = arguments[1];
        }
        A = new Array(len);
        k = 0;
        while (k < len) {
            var kValue, mappedValue;
            if (k in O) {
                kValue = O[k];
                mappedValue = callback.call(T, kValue, k, O);
                A[k] = mappedValue;
            }
            k++;
        }
        return A;
    };
}



/**
 * Mortgage Calculator Controller Class
 */
const MortgageCalculatorController = (function() {

    /**
     * Utilities
     */
    const normalizeNumber = function(num) {
        let result = parseFloat(num);
        if (isNaN(result)) {
            result = 0;
        }
        return result;
    };

    const morphFloorplans = function(floorplans) {
        const morphedFloorplans = floorplans.map(function(obj) {
            return {
                id: obj.id,
                name: obj.name,
                price: obj.price,
                interest_rate: obj.interest_rate,
                est_property_taxes: obj.est_property_taxes,
                est_homeowner_fees: obj.est_homeowner_fees
            };
        });
        return morphedFloorplans;
    };

    const morphCommunities = function(communities) {
        const morphedCommunities = _(communities)
            .remove(function(obj) {
                return obj.availability !== 'upcoming';
            })
            .map(function(obj) {
                return {
                    id: obj.id,
                    name: obj.availability !== 'upcoming' ? obj.name : obj.name + ' (Coming Soon)',
                    price: obj.price,
                    interest_rate: obj.interest_rate,
                    est_property_taxes: obj.est_property_taxes,
                    est_homeowner_fees: obj.est_homeowner_fees,
                    residences: morphFloorplans(obj.residences)
                };
            })
            .value();
        return morphedCommunities;
    };

    function _MortgageCalculatorController($scope, $timeout, mortageCalculatorService, CommunitiesDataService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$onInit = this.init.bind(this);
        this.mortageCalculatorService = mortageCalculatorService;
        this._COMMUNITIES = CommunitiesDataService.communities;
        this.COMMUNITIES = [];
        this.FLOORPLANS = [];
        this.TERM_OPTIONS = [
            { name: '15-Year Fixed', value: 15 },
            { name: '20-Year Fixed', value: 20 },
            { name: '25-Year Fixed', value: 25 },
            { name: '30-Year Fixed', value: 30 },
            { name: '40-Year Fixed', value: 40 }
        ];
        this.chart = {
            labels: ['Principal Interest', 'Taxes', 'Fees/Dues'],
            colors: ['#72a5f7', '#878787', '#2A66C2'],
            options: {
                tooltips: {
                    tooltipTemplate: '$',
                    fontSize: 22,
                    titleFontSize: 22,
                    bodyFontSize: 20,
                },
                //size: {height: 650, width: 650},
                cutoutPercentage: 50,
                elements: {arc: {borderWidth: 0}},
                legend: {
                    display: false,
                    align: 'start',
                    labels: {
                        usePointStyle: true,
                        fontColor: '#838383',
                        fontSize: 22,
                        defaultFontFamily: 'Questrial',
                    }
                }
            }
        }
        this.legend = 'Test';
        this.showPercent = false;
        this.selectCommunity = this.selectCommunity.bind(this);
        this.validate = this.validate.bind(this);
    }

    _MortgageCalculatorController.prototype.init = function() {
        this.SALES_PRICE = this.defaultPrice ? parseFloat(this.defaultPrice) : 0;
        this.DOWN_PAYMENT = parseFloat(this.defaultDownPayment) || 20;
        this.INTEREST_RATE = parseFloat(this.defaultInterestRate) || 0;
        this.PROPERTY_TAX = this.defaultTaxes ? parseFloat(this.defaultTaxes) : 0.97;
        this.HOMEOWNER_FEES = this.defaultHoa ? parseFloat(this.defaultHoa) : 0;
        this.fields = {
            community: this.community || -1,
            floorplan: null,
            salesPrice: this.SALES_PRICE,
            downPayment: undefined,
            downPaymentPercent: this.DOWN_PAYMENT,
            includeExtras: true,
            mortgage: 0,
            pmiPayment: 0,
            tax: 0,
            term: 30,
            loadnAmount: 0,
            insurance: 0,
            interestRate: this.INTEREST_RATE,
            EstimatedYearlyPropertyTaxes: this.PROPERTY_TAX,
            EstimatedMonthlyHomeownerFees: this.HOMEOWNER_FEES
        };

        this.unbindWatcher = this.$scope.$watch('vm._COMMUNITIES', this.selectCommunity, true);
        this.updateFields();
        this.validate();
    };

    /**
     * selectCommunity
     * @param {String} id - Community id
     */
    _MortgageCalculatorController.prototype.selectCommunity = function (communities) {
        this.fields.community = this.community || -1;
        if (communities.length > 0) {
            this.COMMUNITIES = morphCommunities(this._COMMUNITIES);
            this.updateFields();
            this.unbindWatcher();
        }
    };

    /**
     * getSelectedCommunity
     */
    _MortgageCalculatorController.prototype.getSelectedCommunity = function () {
        const communityId = this.fields.community;
        return this.fields.community
            ? this.COMMUNITIES.find(function(obj) {
                return obj.id === communityId;
            })
            : null;
    };

    /**
     * getSelectedFloorplan
     */
    _MortgageCalculatorController.prototype.getSelectedFloorplan = function() {
        const floorplanId = this.fields.floorplan;
        return this.fields.floorplan
            ? this.FLOORPLANS.find(function(obj) {
                return obj.id === floorplanId;
            })
            : null;
    };

    /**
     * updateFields
     */
    _MortgageCalculatorController.prototype.updateFields = function() {
        const floorplan = this.getSelectedFloorplan()
        const community = this.getSelectedCommunity();
        if (!this.dynamic && (floorplan || community)) {
            const model = {
                price: normalizeNumber(floorplan && floorplan.price) || normalizeNumber(community.price),
                interestRate: normalizeNumber(floorplan && floorplan.interest_rate) || normalizeNumber(community.interest_rate),
                ept: normalizeNumber(community.est_property_taxes),
                hoa: normalizeNumber(community.est_homeowner_fees)
            };
            this.FLOORPLANS = community ? community.residences : [];
            this.fields.salesPrice = model.price || this.SALES_PRICE;
            this.fields.interestRate = model.interestRate || this.INTEREST_RATE;
            this.fields.EstimatedYearlyPropertyTaxes = model.ept || this.PROPERTY_TAX;
            this.fields.EstimatedMonthlyHomeownerFees = model.hoa || this.HOMEOWNER_FEES;
            this.fields.downPayment = this.fields.salesPrice
                ? (this.fields.salesPrice * (this.fields.downPaymentPercent / 100)).toFixed(2)
                : null;

            this.fields.loanAmount = this.fields.salesPrice - this.fields.downPayment;
        } else {
            this.fields.interestRate = this.INTEREST_RATE;
            this.fields.downPayment = this.fields.salesPrice
                ? (this.fields.salesPrice * (this.fields.downPaymentPercent / 100)).toFixed(2)
                : null;
            this.fields.loanAmount = this.fields.salesPrice - this.fields.downPayment;
        }
        this.$timeout(this.validate, 0);
    };

    /**
     * validate
     */
    _MortgageCalculatorController.prototype.validate = function(val) {

        if (val && val === 'percent') {
            this.fields.downPayment = this.fields.salesPrice && this.fields.downPaymentPercent
                ? (this.fields.salesPrice * (this.fields.downPaymentPercent / 100)).toFixed(2)
                : null;
            this.fields.loanAmount = this.fields.salesPrice && this.fields.downPayment
                ? (this.fields.salesPrice - this.fields.downPayment)
                : null;
        } else if (val && val === 'currency') {
            this.fields.downPaymentPercent = this.fields.salesPrice && this.fields.downPayment
                ? (this.fields.downPayment / this.fields.salesPrice) * 100
                : null;
            this.fields.loanAmount = this.fields.salesPrice && this.fields.downPayment
                ? (this.fields.salesPrice - this.fields.downPayment)
                : null;
        } else if (val && val === 'loan') {
            this.fields.downPayment = this.fields.salesPrice && this.fields.loanAmount
                ? (this.fields.salesPrice - this.fields.loanAmount)
                : null;
            this.fields.downPaymentPercent = this.fields.salesPrice && this.fields.downPayment
                ? (this.fields.downPayment / this.fields.salesPrice) * 100
                : null;
        } else if (val && val === 'price') {
            this.fields.downPayment = this.fields.salesPrice && this.fields.downPaymentPercent
                ? (this.fields.salesPrice * (this.fields.downPaymentPercent / 100))
                : null;
            this.fields.loanAmount = this.fields.salesPrice && this.fields.downPayment
                ? (this.fields.salesPrice - this.fields.downPayment)
                : null;
        }
        if (this.$scope.mortgageCalculator && this.$scope.mortgageCalculator.$valid) {
            this.calculate();
        }
    };

    /**
     * calculate
     */
    _MortgageCalculatorController.prototype.calculate = function() {

        this.mortgage = 0;
        this.pmiPayment = 0;
        this.tax = 0;
        this.insurance = 0;
        this.interestRate = parseFloat(this.fields.interestRate);
        this.principle = this.fields.salesPrice - this.fields.downPayment;
        this.salePercent = parseFloat((this.fields.downPayment / this.fields.salesPrice) * 100).toFixed(2);
        this.months = this.fields.term * 12;
        this.payments = this.fields.term * 12;
        this.monthlyInterestRate = this.fields.interestRate ? (parseFloat(this.fields.interestRate)) / 100 / 12 : 0;
        this.mortgage = this.monthlyInterestRate
            ? this.principle * this.monthlyInterestRate * (Math.pow(1 + this.monthlyInterestRate, this.payments)) / (Math.pow(1 + this.monthlyInterestRate, this.payments) - 1)
            : this.principle / this.payments;
        this.mortgage = parseFloat(this.mortgage.toFixed(2));

        if (this.PMI && this.PMI > 0 && this.fields.salePercent < 20 && this.fields.includeExtras) {
                this.pmiPayment = this.principle * (this.PMI / 100 / 12);
        }
        this.pmiPayment = parseFloat(this.pmiPayment.toFixed(2));

        if (this.fields.EstimatedYearlyPropertyTaxes && this.fields.EstimatedYearlyPropertyTaxes > 0 && this.fields.includeExtras) {
            this.tax = this.fields.salesPrice * (this.fields.EstimatedYearlyPropertyTaxes / 100 / 12);
        }
        this.tax = parseFloat(this.tax.toFixed(2));

        if (this.fields.EstimatedMonthlyHomeownerFees && this.fields.EstimatedMonthlyHomeownerFees > 0 && this.fields.includeExtras) {
            this.insurance = parseFloat(this.fields.EstimatedMonthlyHomeownerFees);
        }

        this.totalPayment = this.mortgage + this.pmiPayment + this.tax + this.insurance;
        this.feesAndDues = this.pmiPayment + this.insurance;
    };

    return _MortgageCalculatorController;
})();

MortgageCalculatorController.$inject = [
    '$scope',
    '$timeout',
    'mortageCalculatorService',
    'CommunitiesDataService'
];

/**
 * Mortgage Calculator Directive
 * `<mortgage-calculator community=""></mortgage-calculator>
 */
function MortgageCalculatorDirective() {
    return {
        restrict: 'E',
        scope: {
            dynamic: '=',
            community: '=',
            defaultDownPayment: '=downpayment',
            defaultInterestRate: '=interestrate',
            defaultPrice: '=price',
            defaultHoa: '=hoa',
            defaultTaxes: '=taxes'
        },
        templateUrl: 'mortgage-calculator-tpl.html',
        controller: 'MortgageCalculatorController as vm',
        bindToController: true
    };
}



angular.module('app.common')
    .controller('MortgageCalculatorController', MortgageCalculatorController)
    .directive('mortgageCalculator', MortgageCalculatorDirective);
