// 'use strict';
// /* global $, angular */

// angular.module('app.goGreen')
// .directive('carouselNav', [function () {
//   return {
//     restrict: 'A',
//     link: function ($scope, scope, elem, attr) {

//       // $(event.targetElement).on('click',function(e){
//       //   e.preventDefault();
//       //   console.log(attr.parentSlide);

//       //   var greenCarousel = $("#goGreenCarousel");
//       //   //$(this).attr(attr.slide).toggleClass("zoom");
//       //   // $(greenCarousel).carousel({
//       //   //   interval: 200000,
//       //   //   pause: "false"
//       //   // });
//       //   // $(greenCarousel).carousel('pause');
//       //   $(greenCarousel).carousel(attr.parentSlide);



//       // // function advanceCarousel(){
//       // //   console.log(attr.parentSlide);
//       // //   $(greenCarousel).carousel(attr.parentSlide);
//       // //  // $(attr.targetElement).attr("data-slide-to", 2)
//       // // }
//       // //setTimeout($(greenCarousel).carousel(attr.parentSlide), 800);

//       // });

//       $scope.cycle = function(evt, attr) {
//         //console.dir(evt.target);
//         evt.preventDefault();
//         var greenCarousel = $("#goGreenCarousel");
//         //console.log(attr.parentSlide);
//         var slideNumber = $(evt.target).attr("parent-slide");
//         console.log(slideNumber);
//         $(greenCarousel).carousel($(evt.target).attr("parent-slide"));
//       }
//     }

//   };
// }])