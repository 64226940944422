'use strict';
/* global $, angular */

angular.module("app.common").directive('dropdownButton', [function () {
	return {
		restrict: 'A',
		link: function (scope, elem, attrs) {
			elem.find("li").bind("click",function(){
				elem.find("span.text").text($(this).text());
			})
		}
	};
}])