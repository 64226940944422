angular.module('app.forRealtors')
.controller('ForRealtorsCtrl', ['$scope', '$rootScope', '$window', '$http', 'CommunitiesDataService', 'dummyDataService', function ($scope, $rootScope, $window, $http, CommunitiesDataService, dummyDataService) {
  $scope.states = dummyDataService.newStates();
  $scope.hears = dummyDataService.newHears();
  $scope.communities = [];

  // for community dropdown
  CommunitiesDataService
    .promise
    .then(function(communities) {
      _.each(communities, function (community) {
        $scope.communities.push({ key: community.id, value: community.name });
      });
    });

  $scope.signup = {};
  $scope.signup.form = 'RealtorsForm';
  $scope.signup.origin = window.location.href;
  $scope.signup.type = 'signUp';
  $scope.signup.realtors = 'I am a Realtor';

  if (typeof window.LassoCRM !== 'undefined' && window.LassoCRM) {
    $scope.signup.guid = LassoCRM.tracker.readCookie('ut');
  }

  $scope.hideForm = false;
  $scope.showMessage = false;
  $scope.showLoader = false;
  $scope.triedSubmit = false;

  $scope.noCommunities = function() {
    _.each($scope.signup.communities, function(v, k) {
      if (!v) {
        delete $scope.signup.communities[k];
        }
    });
    if (Object.keys($scope.signup.communities).length === 0) {
      return true;
    }
    return false;
  };

  $scope.checkFormValidation = function(form) {
    $scope.triedSubmit = true;

    if (!form.$valid) {
      return;
    }

    if ($scope.noCommunities()) {
      return;
    }

    processForRealtorsForm();
  };

  function processForRealtorsForm() {
    $scope.hideForm = true;
    $scope.showLoader = true;
    $http({
      method: 'POST',
      url: '/form/to-database',
      data: $.param($scope.signup), // pass in data as strings
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      } // set the headers so angular passing info as form data (not request payload)
    })
    .success(function(data) {
      $scope.showLoader = false;
      if (data.status === 'success') {

        // Activate this when we have the details
        sendSuccessSeo(data);

        // Add script tag for mathtag
        const scriptElem = document.createElement('script');
        scriptElem.src = 'https://pixel.mathtag.com/event/js?mt_id=1574576&mt_adid=250760&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=';
        document.body.appendChild(scriptElem);

        $scope.message = data.message;
        $scope.showMessage = true;

        $window.loadPseudoConfirmation();
      } else {
        $scope.message = data.message;
        $scope.showMessage = true;
      }
    });
  }

  var sendSuccessSeo = function(data) {
    if (typeof clicky_custom === 'undefined' || typeof clicky === 'undefined') { 
      console.log('clicky is undefined. Tracking not firing.');
      return;
    }

    // Tie lead id and email to clicky analytics data
    clicky_custom.visitor = {
      lead_id: data.id,
      email: data.email,
      signup: "1"
    };

    clicky_custom.goal = {
      name: 'Quick Signup'
    };

    // Log action in clicky
    clicky.goal('113');                     // Logs a "quick-signup" goal hit in clicky
    clicky.log('/quick-signup/thank-you');  // Logs a fake page hit in clicky

    // Fire off a Google Analytics goal hit
    ga('create', 'UA-846906-1');
    ga(function(tracker) {
      ga('send', 'pageview', '/ajax-goals/quick-signup');
    });
  };

}]);
