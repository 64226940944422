angular.module('app', [
    'ngResource',
    'app.homepage',
    'app.aboutProfile',
    'app.careers',
    'app.communityList',
    'app.forRealtors',
    'app.owning',
    'app.philanthropy',
    'app.socialGovernanceStatement',
    'app.executiveTeam',
    'app.portfolio',
    'app.goGreen',
    'app.city',
    'app.common',
    //'app.microsite',
    'app.comingSoon',
    'app.interactiveFloorplans',
    'app.contact',
    'app.buyingYourHome',
    'app.companyProfile',
    'app.homesNowReady',
    'app.journeyToHomeownership',
    'app.quickSignUp',
    'app.fullInterestList',
    'app.salesOffice',
    'app.communityCard',
    'app.residenceCard',
    'app.homeCard',
    'app.mediaGallery',
    'app.announcements',
    'app.footer',
    'app.scheduleTour',
    'app.corporateWebsite',
    'app.locallyOwned',
    'chart.js',
    'ui.bootstrap',
    'ngTouch',
    'ngSanitize',
    'modules.dataServices',
    'modules.trustHtml',
    'modules.trustAsResourceUrl',
    'modules.googleMaps',
    'modules.price',
    'modules.device',
    'modules.priceCustom',
    'modules.propertyTypes'
])
.config(['$interpolateProvider', function($interpolateProvider) {
  $interpolateProvider.startSymbol('<%');
  $interpolateProvider.endSymbol('%>');
}])

.service('scrollMagic', ['$rootScope', function ($rootScope) {
  //$(document).ready(function(){
      var controller = new ScrollMagic.Controller();
          var scene = new ScrollMagic.Scene({
                  triggerElement: "#" + attrs.id,
                  triggerHook: 'onEnter',
                  offset: attrs.offset
              })
              //.addIndicators()
              .addTo(controller)
              .on("enter", function (e) {
                  if(!elem.hasClass('in')){
                      elem.addClass('in');
                      if(attrs.stagger !== undefined)
                      {
                        setTimeout(function(){
                          elem.find(attrs.stagger).velocity("transition."+attrs.transition,{stagger: attrs.speed});
                        },attrs.delay)
                      }
                  }
              })
              .on("leave", function(e){
                 if(attrs.toggle !== undefined) {
                  elem.removeClass('in');
                }
              })

              this.scrollSpy = function(trigger, classname, triggerEvent, offsetnum){
                  var scene = new ScrollMagic.Scene({
                          triggerElement: '#'+trigger,
                          triggerHook: triggerEvent,
                          offset: offsetnum
                      })
                      .on("enter leave", function(e){
                          var $target = $('#subnav li[target="' +  trigger + '"]');
                          if(e.type == 'leave'){
                              if($target.prev() !== 0){
                                  $target = $target.prev();
                              }
                          }

                          $target.addClass(classname).siblings().removeClass(classname);
                          $rootScope.$broadcast('scrollspyTriggered',{url:$target.attr('target')});
                      })
                      //.addIndicators({name: trigger})
                      .addTo(controller);
              }
   // })

}])

angular.module('app.homepage', ['modules.staticMap'])
  .config(function() {});

angular.module('app.aboutProfile', [])
	.config(function() {});

angular.module('app.announcements', [])
  .config(function() {});

angular.module('app.careers', [])
  .config(function() {});

angular.module('app.city', [])
  .config(function() {});

angular.module('app.communityList', [])
  .config(function() {});

angular.module('app.forRealtors', [])
  .config(function() {});

angular.module('app.owning', [])
  .config(function() {});

angular.module('app.philanthropy', [])
  .config(function() {});

angular.module('app.socialGovernanceStatement', [])
  .config(function() {});

angular.module('app.executiveTeam', [])
  .config(function() {});

angular.module('app.portfolio', ['modules.device'])
  .config(function() {});

angular.module('app.goGreen', [])
  .config(function() {});

angular.module('app.contact', [])
.config(function() {});

angular.module('app.buyingYourHome', [])
.config(function() {});

angular.module('app.companyProfile', ['modules.dataServices'])
.config(function() {});

angular.module('app.homesNowReady', [])
.config(function() {});

angular.module('app.journeyToHomeownership', [])
.config(function() {});

angular.module('app.quickSignUp', ['modules.laravelParameters'])
.config(function() {});

angular.module('app.scheduleTour', ['modules.laravelParameters'])
  .config(function() {});

angular.module('app.fullInterestList', [])
.config(function() {});

angular.module('app.salesOffice', [])
.config(function() {});

angular.module('app.common', [])
  .config(function() {});

angular.module('app.mediaGallery', ['youtube-embed'])
  .config(function() {});

angular.module('app.corporateWebsite', [])
  .config(function() {});

angular.module('app.locallyOwned', ["angular-inview"])
  .config(function() {});

angular.module('app.comingSoon', ["angular-inview"]);  // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
angular.module('app.interactiveFloorplans', ["angular-inview"]);  // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]

// Fade in navbar
$(function() {
  //$("#homepageCarousel").addClass("fade-in-slideshow");
  $('.carousel-control').addClass('slide-in');
   $('#navbar-home').addClass('fade-in-nav');
});

// sticky header nav on scroll
$(function () {
  if(!$('body').hasClass('no-animation')) {
    var headerController = new ScrollMagic.Controller();

      // Create the Scene and trigger when visible with ScrollMagic
  	var headerScene = new ScrollMagic.Scene({
  		triggerHook: "onLeave",
      triggerElement: 'span.nav-trigger',
      //duration: 0,
      //offset: 0,
  	})
  	//.setPin("#navbar-home")
  	.setClassToggle(".navigation, .site-header", "fixed")
  	.addTo(headerController);
}
});


(function initSlideout(){

  var panel = document.getElementById('panel');
  var menu = document.getElementById('navbar-home');

  if (panel && menu) {
    //mobile slideout nav
     var slideout = new Slideout({
        'panel': panel,
        'menu': menu,
        'side': 'right',
        //'padding': 275,
        //'tolerance': 70
      });

    // Toggle button
     $('.toggle-button').on('click', function() {
      slideout.toggle();
      $("#navbar-home").toggleClass("open");
    });

     slideout.on('beforeopen', function() {
     // document.querySelector('.fixed-position').classList.add('fixed-open');
      //$(".fixed-position").addClass("fixed-open");
      $("#panel").addClass("is-open");
    });

    slideout.on('beforeclose', function() {
      //$(".fixed-position").removeClass("fixed-open");
      $("#panel").removeClass("is-open");
    });

    slideout.on('close', function() {
      $(".toggle-button").removeClass("opened");
     // $("li.dropdown .dropdown-menu").velocity("slideUp");
    });
  }

})();

// Toggle nav items on click
$('ul.left-menu li.dropdown a.dropdown-toggle').on('click', function() {
  $(this).find(".dropdown-menu").toggleClass("open");
});

var mobileDeviceHeight = $(window).height();
function mobileNav() {
  //if( $(window).width() < 767 ) {
    //if($("html").hasClass("touchevents")) {
    $("#navbar-home").css('height', mobileDeviceHeight);

    // Close the submenu if width drops below 768px
    var openNav = document.querySelector('.dropdown-menu.active');

    if (openNav && $(window).width() < 768) {
      openNav.classList.remove('active');
    }
    // $(".navbar-nav a.dropdown-toggle").attr({
    //   href:"#",
    // });
    // $('.navbar-nav a.dropdown-toggle').attr('data-toggle', 'dropdown');

    //$(".navbar-header").unwrap();
  //}
}

mobileNav();

$(window).resize(function(){

    mobileNav()

  });


// remove #panel positioning when modal is open

$('.modal').on('show.bs.modal', function (e) {
  var hasIframe = $(this).find('iframe').length > 0;
  var hasVimeoVideo = hasIframe && $(this).find('iframe').attr('src').indexOf('vimeo') > -1;

  if (hasVimeoVideo) {
    var iframe = $(this).find('iframe');
    var player = window.Vimeo ? new Vimeo.Player(iframe) : null;

    if (player) {
      player.play();
    }
  }
   //$('#panel').css('position', 'static');
   $('#panel').addClass('modal-open');
});

$('.modal').on('hide.bs.modal', function (e) {
  var hasIframe = $(this).find('iframe').length > 0;
  var hasVimeoVideo = hasIframe && $(this).find('iframe').attr('src').indexOf('vimeo') > -1;

  if (hasVimeoVideo) {
    var iframe = $(this).find('iframe');
    var player = window.Vimeo ? new Vimeo.Player(iframe) : null;

    if (player) {
      player.pause();
    }
  }

   //$('#panel').css('position', 'relative');
   $('#panel').removeClass('modal-open');
});
