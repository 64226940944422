'use strict';
/* global $, angular */

angular.module('app.common')
.directive('hoverPopover', function($compile, $templateCache, $timeout, $rootScope) {
    var getTemplate = function(contentType) {
        return $templateCache.get('PopoverTemplate.html');
    };
    var getTemplate2 = function(contentType) {
        return $templateCache.get('PopoverTemplate2.html');
    };
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var content = '';

            if (attrs.class === 'site-plan-pin'){
                content = getTemplate2();
            }else{
                content = getTemplate();
            }

            $rootScope.insidePopover = false;
            $(element).popover({
                content: content,
                placement: 'top',
                html: true
            });
            $(element).bind('mouseenter', function(e) {
                $timeout(function() {
                    if (!$rootScope.insidePopover) {
                        $(element).popover('show');
                        scope.attachEvents(element);
                    }
                }, 50);
            });
            $(element).bind('mouseleave', function(e) {
                $timeout(function() {
                    if (!$rootScope.insidePopover)
                        $(element).popover('hide');
                }, 50);
            });

        },
        controller: function($scope, $element) {
            $scope.attachEvents = function(element) {
                $('.popover').on('mouseenter', function() {
                    $rootScope.insidePopover = true;
                    $(element).find(".shadow").addClass("show");
                });
                $('.popover').on('mouseleave', function() {
                    $rootScope.insidePopover = false;
                    $(element).popover('hide');
                    $(element).find(".shadow").removeClass("show");
                });
            };

            // $scope.hoverOver = function(event) {
            //     $(event.target).addClass('active-pin');
            // };
            // $scope.hoverOff = function(event) {
            //     $(event.target).removeClass('active-pin');
            // };
        }
    };

});