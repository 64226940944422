(function AppHomepageControllersCommunitiesCachedDataService() {
  "use strict";

  angular

    .module('modules.dataServices')

    /* global jsonCache */
    .service("CommunitiesCachedDataService", [
      function() {

        var data = {
          communities: []
        };

        if (typeof jsonCache === "undefined") {
          return data;
        }

        if (jsonCache.communities === undefined) {
          return data;
        }

        data.communities = jsonCache.communities;

        return data;
      }
    ]);

})();