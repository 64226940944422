/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
    'use strict';
    angular.module('app.common')
      .directive('hashLink', [function() {
        return {
          restrict: 'A',
          
          link: function(scope, elem, attrs) { 

  
            elem.on('click', function(e) {
                var targetElement = elem;
  
              if (attrs.targetElement) {
                targetElement = attrs.targetElement;
              }
          
  
              $(targetElement).removeClass('active');

            })  
          }
        };
      }]);
  }());