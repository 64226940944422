(function() {
  "use strict";

  angular
    .module("app.homeCard")

    .directive('homeImage', [ function() {

      return {
        restrict: 'A',
        scope: {
          home: '='
        },
        link: function(scope, element, attr) {

          var validImages = [
            "home.elevation.img.large",
            "home.residence.elevations[0].img.large",
            "home.residence.carouselImages[0].image.large",
            "home.residence.community.coverImage.large",
            "home.community.coverImage.large",
            "home.community.elevations[0].img.large",
            "home.community.residences[0].elevations[0].img.large",
            "home.community.carouselImages[0].image.large",
            "'/images/not_found.jpg'"
          ];


          var nextImage = function(){
            var nextSrc = validImages.shift();
            // Don't forget to eval the src, since it can use scope attributes
            nextSrc = scope.$eval(nextSrc);
            if (nextSrc) {
              element[0].src = nextSrc;
            }
          };

          var errorHanlder = nextImage;

          element.on('error', errorHanlder);

          scope.$on('$destroy', function() {
            element.off('error', errorHanlder);
          });

          nextImage();

        }
      };
    }]);
})();
