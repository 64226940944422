(function AppHomepageControllersCommunitiesMap() {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("CommunitiesDataService", ['$http', '$q', 'CommunitiesCachedDataService',
      function($http, $q, CommunitiesCachedDataService) {

        var data = {
          communities: [],
          promise: null
        };

        if (CommunitiesCachedDataService.communities.length > 0) {

          // Wrap the cached data in a promise to provide a consistent api
          data.promise = $q.when(CommunitiesCachedDataService.communities);

        } else {

          data.promise = $http.get('/api/community', {
            cache: true
          })
            .then(function(result) {
              return result.data.communities;
            });

        }

        // This lets you use the service in a simple Angular-y way, just
        // wait for the promise to resolve internally, and let $digest
        // discover the data.
        data.promise
          .then(function(communities) {
            angular.copy(communities, data.communities);
          });

        return data;
      }
    ]);

})();
