(function() {
  "use strict";

  angular
    .module('app.microsite') // add back in once bower files gets sorted ["angular-inview", "smoothScroll"]
    .controller('micrositeNavCtrl', ['$scope', "$location", "CommunityDataService", "laravelParameters", "micrositeSubNavReveal",
      function($scope, $location, CommunityDataService, laravelParameters, micrositeSubNavReveal) {
         
        $scope.revealFaqPanel = function(){
          console.log("Show the FAQ!");
        };

        $scope.revealLendersPanel = function(){
          console.log("Show the Lenders!");
        };

      }
    ])
  ;

})();
