/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
    'use strict';
    angular.module('app.common')
      .directive('nav', [function() {
        return {
          restrict: 'A',
          scope: {
           // hash: "@",
            //toggleText: '=' 
        },
          link: function(scope, elem, attrs) {
  
            elem.on('click', function(e) {
                e.preventDefault();
                
                var target = elem;
  
              if (attrs.target) {
                target = attrs.target;
              }
          
  
              $(target).toggleClass('active').siblings().removeClass('active');

            //   window.addEventListener('hashchange', function() { 
            //     console.log("changed");
            //   });

            // scope.$watch('toggleText', function() {
            //     console.log("from watch");
            //     if($('.communities.dropdown-menu').hasClass('active')) { 
            //         $rootScope.toggleText = false;     
            //         console.log($rootScope.toggleText);
            //     }
            // });
             
            // console.log(scope.toggleText);
            })
  
          }
        };
      }]);
  }());