'use strict';
/* global $, angular */

angular.module('app.common')
.directive('fadeInDropdown', [function () {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {

    	elem.on('click',function(event){
    		event.stopPropagation();
    		$(attr.targetElement).fadeToggle(600);  
	    });
    }
  };
}])