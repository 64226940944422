angular
  .module('modules.snapshotRange')

  .filter('snapshotRange', ['$filter', function($filter) {
    'use strict';

    return function(param, fractionSize, symbol) {
      var formatter = new Intl.NumberFormat('en-us', {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionSize,
      });

      if (param === undefined || param === null) {
        return (symbol === '$') ? "Pricing To Be Determined" : "TBD";
      }

      if (param.price_status === 'Anticipated' || param.price_status === 'Future Release' || param.price_status === 'Temporarily Sold Out' || param.price_status === 'Pricing To Be Determined' || param.price_status === 'Sold Out') {
        return param.price_status;
      }

      var symbol = symbol || '';

      var input = {
        low: 0,
        high: 0
      };

      if (typeof param === 'object') {
        input.low = parseFloat(param.low);
        input.high = parseFloat(param.high);
      } else {
        input = {
          low: parseFloat(param),
          high: 0
        };
      }

      // Don't display whole numbers with fractional syntax
      // "1.0 - 2.5" should be "1 - 2.5"
      // var lo_fraction = (input.low === Math.floor(input.low)) ? 0 : fractionSize;
      // var hi_fraction = (input.high === Math.floor(input.high)) ? 0 : fractionSize;

      var lo = input.low || 0;
      var hi = input.high || 0;

      if (lo === 0 && hi === 0) {
        return (symbol === '$') ? "Pricing To Be Determined" : "TBD";
      }

      if (hi === 0) {
        return symbol + formatter.format(lo);
        // return symbol + $filter('number')(lo, lo_fraction);
      }

      if (lo === 0) {
        return symbol + formatter.format(hi);
        // return symbol + $filter('number')(hi, hi_fraction);
      }

      if (hi > lo) {
        return symbol
          + formatter.format(lo)
          + '-'
          + symbol
          + formatter.format(hi);
        // return symbol + $filter('number')(lo, lo_fraction) + ' - ' + symbol + $filter('number')(hi, hi_fraction);
      }

      return symbol + formatter.format(lo);
      // return symbol + $filter('number')(lo, lo_fraction);
    };
  }])
;
