angular
  .module('modules.propertyTypes')

  .filter('propertyType', ['$filter', function($filter) {
    'use strict';

    return function(param, plural) {

      if (!plural) {
        plural = 'single';
      }

      var propertyTypes = {
        single_family: {
          single: "Single Family Home",
          plural: "Single Family Homes"
        },
        condo: {
          single: "Condominium",
          plural: "Condominiums"
        },
        townhouse: {
          single: "Townhouse",
          plural: "Townhouses"
        },
        carriage: {
          single: "Carriage House",
          plural: "Carriage Houses"
        },
        garden: {
          single: "Garden Home",
          plural: "Garden Homes"
        },
        duplex: {
          single: "Duplex",
          plural: "Duplexes"
        },
        multiplex: {
          single: "Multiplex",
          plural: "Multiplexes"
        },
        attached: {
          single: "Attached Home",
          plural: "Attached Homes"
        }
      };

      if (typeof propertyTypes[param] !== "undefined") {
        return propertyTypes[param][plural];
      }

      return (plural === 'plural') ? "Homes" : "Home";
    };
  }])
;