// This is a hack. It's here because we are using laravel routing
// instead of angular, and I can't get laravel routes to mesh with
// $routeParams no matter what. -wo

(function(){
  "use strict";

  angular.module("modules.laravelParameters", []);

})();
