(function() {
    'use strict';

    angular
        .module('app.announcements')
        .controller('AnnouncementsCtrl', [
            '$window',
            '$timeout',
            function($window, $timeout) {
                this.root = null;
                this.prefersReducedMotion = matchMedia('(prefers-reduced-motion: reduce)').matches;
                this.isPlaying = false;
                this.width = $window.innerWidth;
                this.height = 0;
                this.tickerTimeout = null;
                this.animationDuration = 2000;
                this.pauseDuration = 8000;
                this.currentAnnouncement = 0;
                this.announcementCount = this.announcements.length;
                this.isMobile = false;

                // Computed properties:
                this.translate = this.width * this.currentAnnouncement;
                this.trackStyle = {
                    transition: this.currentAnnouncement === 0
                        ? 'none'
                        : 'transform' + this.animationDuration + 'ms ease-in-out',
                    transform: 'translateX(-' + (this.width * this.currentAnnouncement) + 'px)',
                };

                this.animate = (function() {
                    this.currentAnnouncement = this.currentAnnouncement === this.announcementCount ? 0 : this.currentAnnouncement + 1;
                    this.translate = this.width * this.currentAnnouncement;
                    this.trackStyle = {
                        transition: this.currentAnnouncement === 0
                            ? 'none'
                            : 'transform ' + this.animationDuration + 'ms ease-in-out',
                        transform: 'translateX(-' + (this.width * this.currentAnnouncement) + 'px)'
                    };
                    this.play();
                }).bind(this);

                this.play = function() {
                    this.isPlaying = true;
                    if (this.tickerTimeout) {
                        $timeout.cancel(this.tickerTimeout);
                    }
                    if (this.currentAnnouncement === 0) {
                        this.tickerTimeout = $timeout(this.animate, this.pauseDuration);
                    } else if (this.currentAnnouncement !== this.announcementCount) {
                        this.tickerTimeout = $timeout(this.animate, this.animationDuration + this.pauseDuration);
                    } else {
                        this.tickerTimeout = $timeout(this.animate, this.animationDuration);
                    }
                };

                this.pause = function() {
                    this.isPlaying = false;
                    if (this.tickerTimeout) {
                        $timeout.cancel(this.tickerTimeout);
                    }
                };

                this.detectMobile = function() {
                    this.width = window.innerWidth;
                    this.isMobile = $window.innerWidth < 768;
                };

                this.$postLink = function() {
                    this.root = $window.document.documentElement;
                    if (!this.prefersReducedMotion && this.announcementCount > 1) {
                        this.play();
                    } else {
                        this.width = window.innerWidth - 32;
                    }

                    this.translate = this.width * this.currentAnnouncement;
                    this.trackStyle = {
                        transition: 'none',
                        transform: 'none',
                    };
                };

                this.$onDestroy = function() {
                    angular.element($window).off('resize', this.detectMobile);

                    if (this.tickerTimeout) {
                        $timeout.cancel(this.tickerTimeout);
                    }

                    if (this.resizeObserver) {
                        this.resizeObserver.disconnect();
                    }
                };

                this.detectMobile();

                angular.element($window).on('resize', this.detectMobile);
            }
        ])
        .directive('announcements', [
            function() {
                return {
                    restrict: 'A',
                    templateUrl: 'announcements.html',
                    scope: { announcements: '=' },
                    bindToController: true,
                    controller: 'AnnouncementsCtrl as vm',
                };
            }
        ]);
})();
