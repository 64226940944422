(function(){
  "use strict";

  angular
    .module("app.homeCard", [
      "app.homeCard.directives"
    ]);

  angular
    .module("app.homeCard.directives", []);
    
})();