'use strict';
/* global $, angular */

angular.module('app.microsite')
.directive('featuresAccordion', [function () {
  return {
    restrict: 'A',
    link: function (scope, elem) {

    	elem.on('click',function(e){
    		if(!elem.hasClass('active')){
	    		//elem.closest('.panel-group').find('.active').removeClass('active'); 
          e.preventDefault(); 
          $('h4.panel-title a.active').removeClass('active'); 
    		}
        e.preventDefault(); 
	    	elem.toggleClass('active'); 
	    });

      
    }
  };
}]);