angular.module('app.owning')
.controller('OwningCtrl',['$scope', '$rootScope', '$window', '$http', 'dummyDataService', 'device', function ($scope, $rootScope, $window, $http, dummyDataService, device) {
  $scope.states = dummyDataService.newStates();

  $scope.signup = {};
  $scope.signup.origin = window.location.href;
  $scope.signup.type = 'warrantyRequests';

  $scope.hideForm = false;
  $scope.showMessage = false;
  $scope.showLoader = false;
  $scope.triedSubmit = false;

  $scope.checkFormValidation = function(form) {
    $scope.triedSubmit = true;

    if (!form.$valid) {
      return;
    }

    processWarrantyRequestsForm();
  };

  function processWarrantyRequestsForm() {
    $scope.hideForm = true;
    $scope.showLoader = true;
    $http({
      method: 'POST',
      url: '/form/to-log-file',
      data: $.param($scope.signup), // pass in data as strings
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      } // set the headers so angular passing info as form data (not request payload)
    })
    .success(function(data) {
      $scope.showLoader = false;
      if (data.status === 'success') {
        $scope.message = data.message;
        $scope.showMessage = true;

		$window.loadPseudoConfirmation();
      } else {
        $scope.message = data.message;
        $scope.showMessage = true;
      }
    });
  }



	$scope.maintenanceTips = [
		{
			title: "air conditioning",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "appliances",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "brickwork and mansory",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "cabinetry",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "caulking",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "concrete",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "counter & wall ceramic tile",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "cultured marble",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "doors",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "drywall",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "electrical",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "exteriors",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "floor coverings",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "Grading/Landscaping/Drainage",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "heating systems",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "lumber & millwork",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "painting & staining materials",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "pest control",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "plumbing",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "roof",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		},
		{
			title: "window, mirrors, screens",
			text: "<p>Brick, by its nature, tends to be irregular in size and shape and normally contains some chips and/or surface cracks. Don't expect each brick to be perfect and spaced perfectly. Small surface chips or cracks and slight variations in size and placement are normal and help to create the texture, beauty, and interest of brickwork.</p>"
		}
	];

	  // The function actually applying the offset
function offsetAnchor() {
    if(location.hash.length !== 0) {
        window.scrollTo(window.scrollX, window.scrollY - 50);
    }
}

// This will capture hash changes while on the page
$(window).on("hashchange", function () {
    offsetAnchor();
});

// scroll to hash on page load
$(window).on("load", function() {
	var locationHash = location.hash;
	console.log(locationHash);
	if(locationHash == '#/maintenance') {
	  window.scrollTo(window.scrollX, $('#maintenance').offset().top - 300);
	} else if (locationHash == '#/warranty') {
	  window.scrollTo(window.scrollX, $('#warranty-request__wrap').offset().top - 100);
	}
  });

// This is here so that when you enter the page with a hash,
// it can provide the offset in that case too. Having a timeout
// seems necessary to allow the browser to jump to the anchor first.
window.setTimeout(function() {
    offsetAnchor();
}, 1);

// open first awards panel on page laod
$("#accordionOwning a:first").trigger("click"); 
$("#accordionOwning .header:first").addClass('active');

// prevent open collapse from closing on click
if ($(window).width() > 992) {
  $('.header a').on('click',function(e){
	  $('html, body').animate({
		  scrollTop: $('.maintenance-scroll-offset').offset().top 
	  }, 750);
	if($(this).parents('.panel').children('.card-content').hasClass('in')){ 
		e.stopPropagation();
	}
  });
}

}]);