'use strict';
/* global $, angular */

angular.module('app.goGreen')
.directive('carouselNav', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {


    	// var naturalResourcePin = $(".pin.natural-resources"),
    	// 	healthierHomesPin = $(".pin.healthier-homes"),
    	// 	waterConservancyPin = $(".pin.water-conservancy"),
    	// 	energyEfficientPin = $(".pin.energy-efficient"),
    	// 	caption = $(".caption");
      scope.click = function(index, $event){
      	if($("a.menu.active").index($event.index) < $($event.currentTarget).index($event.index)) {
          $(".item.active").removeClass("no-zoom");
          $(".pin.natural-resources").fadeOut(300);
          $timeout(function(){
       	  	$(".pin.healthier-homes").fadeOut(300);
       	  }, 200);
       	  $timeout(function(){
       	  	$(".pin.water-conservancy").fadeOut(300);
       	   }, 300);
       	  $timeout(function(){
       	  	$(".pin.energy-efficient").fadeOut(300);
       		}, 400);
          $timeout(function(){
            $(".pin.technology").fadeOut(300);
          }, 500);
          $timeout(function(){
            $(".next-step").fadeOut(300);
          }, 500);
       	  
       	  $(".caption").addClass("fade-out");
       	
		    $timeout(function(){
		      $('.carousel').carousel(index); //This will be delayed for one second
		      $(".item").addClass("no-zoom");
		      $(".caption").removeClass("fade-out");
		    }, 1400);
		} else {
			$('.carousel').carousel(index);
		}
      }
  
    }

  };
}]);