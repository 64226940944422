angular
  .module('modules.priceCustom')

  .filter('priceCustom', ['$filter', function($filter) {
    'use strict';

    return function(param) {

      if (param.price_status === 'Pricing To Be Determined' || param.price_status === 'Temporarily Sold Out' || param.price_status === 'Sold Out') {
        return param.price_status;
      }

      if (param === undefined || param === null || param === 0 || param === '' || param === "0") {
        return "Pricing To Be Determined";
      }

      var result = "";

      if (param.price_status === 'Pricing Available') {
        result += "Priced";
      }

      if (param.price_status === 'Anticipated') {
        result += param.price_status;
      }

      result += " from ";

      if (param.price_quantifier) {
        result += "the " + param.price_quantifier;
      }

      result += " $" + $filter('number')(param.price, 0) + "s";

      return result;

    };
  }])
;