'use strict';
/* global $, angular */

angular.module('app.common')
    .directive('scrollBar', ['$timeout',
        function($timeout) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                    //$(elem).css('opacity',0);

                    $(window).on('load',function(){
                        if ($(elem).mCustomScrollbar) {
                            $(elem).mCustomScrollbar({
                                setHeight: attrs.setHeight,
                                scrollbarPosition: 'inside',
                                live: true,
                                scrollInertia: 0,
                            });
                        }
                    })
                }
            };
        }
    ])
