'use strict';
/* global $, angular */

angular.module('app.common')
.directive('scrollTo', [function () {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {

	    elem.on('click', function(event)
	    {
	    	console.log("is clicked");
	        event.preventDefault();

        $(elem.attr('href')).velocity('scroll', {
            duration: 1000,
            offset: -elem.data('offset'),
            delay: elem.data('delay'),
            easing: 'ease-in-out'
        });
	    });
    }
  };
}])