(function() {
  "use strict";

  angular
    .module('app.microsite', [
      'ngResource',
      'ngRoute',
      'ngSanitize',
      "angular-inview",
      "app.common",
      "app.quickSignUp",
      "app.interactiveFloorplans",
      "app.residenceCard",
      'app.scheduleTour',
      "app.footer",
      'app.announcements',
      "modules.dataServices",
      "modules.device",
      "modules.priceStartingAt",
      "modules.propertyTypes",
      "modules.snapshotRange",
      "modules.googleMaps",
      "modules.price",
      "modules.laravelParameters",
      "modules.priceCustom",
      "modules.trustAsResourceUrl",
      'youtube-embed',
      'angular.filter',
      'ui.bootstrap',
      'chart.js'
    ])
    .config(['$interpolateProvider', function($interpolateProvider) {
      $interpolateProvider.startSymbol('<%');
      $interpolateProvider.endSymbol('%>');
    }])
    .config(['$routeProvider', '$locationProvider',
      function($routeProvider, $locationProvider) {
        $locationProvider.html5Mode(true);

        $routeProvider
          .when('/:page', {
          })
          .otherwise({
            template: "<div></div>",
            controller: ['$window', '$location', '$rootScope',
              function($window, $location, $rootScope) {
                $window.location.href = $location.absUrl();
              }
            ]
          })
        ;
      }
    ])
    .service('MicrositeScrollAnimationQueue', [function() {
      var data = {
        animation: false
      };

      return data;
    }])
    .run(['$rootScope', '$location', '$anchorScroll', '$routeParams', '$timeout', 'MicrositeScrollAnimationQueue', '$window',
      function($rootScope, $location, $anchorScroll, $routeParams, $timeout, MicrositeScrollAnimationQueue, $window) {
        var scrollToUrl = function() {

          var hash = $location.path() ? $location.path().replace(/^(\/)/, '#') : null;

          var needtoScroll = 0;

          if (!hash) {
            $('html, body').stop(true).animate({
              scrollTop: needtoScroll
            }, 800, 'easeOutQuart');
          }

          var a = $(hash);
          var offset = 67;


          if (a.length) {

            if (a.data !== undefined) {
              if (a.data().offset !== undefined) {
                offset = a.data().offset;
              }
            }

            if ($('#sub-nav .snapshot-info:hidden').length > 0) {
              console.log('hidden');
              offset = 45;
            }

            console.log("offset", offset);

            needtoScroll = a.offset().top - offset;

            $('html, body').stop(true).animate({
              scrollTop: needtoScroll
            }, 800, 'easeOutQuart');

          } else {

            if (hash === '#preferred-lenders') {
              $('#modal-preferred-lenders').modal('show');
            }
            else
            {
              $anchorScroll(hash);
            }

          }

        };

        // Manuall scrolling stops any animations
        $('html, body').bind('scroll mousedown wheel DOMMouseScroll mousewheel keyup', function(e){
          $("html, body").stop(true);
        });

        $rootScope.$on('$routeChangeStart', function() {
          $rootScope.isRouteLoading = true;
        });

        $rootScope.$on('$locationChangeStart', function() {
          $rootScope.isRouteLoading = true;
        });

        //when the route is changed scroll to the proper element.
        $rootScope.$on('$routeChangeSuccess', function(newRoute, oldRoute) {
          scrollToUrl();
        });

        $rootScope.$on('$locationChangeSuccess', function() {
          scrollToUrl();
        });

        $rootScope.scrollToUrl = scrollToUrl;
      }
    ])

    .directive('galleryModal', [
      function(){
        return {
          templateUrl: "galleryModalTemplate.html",
          scope: {
            "gallery": "="
          },
          link: {

          }
        };
      }
    ])
  ;



})();
